import { NotificationsService } from 'angular2-notifications';
var NotificationService = /** @class */ (function () {
    function NotificationService(_notificationsService) {
        this._notificationsService = _notificationsService;
    }
    NotificationService.prototype.createSuccessNotification = function (notificationTitle, notificationMessage) {
        this._notificationsService.success(notificationTitle, notificationMessage);
    };
    NotificationService.prototype.createErrorNotification = function (notificationTitle, notificationMessage) {
        this._notificationsService.error(notificationTitle, notificationMessage);
    };
    return NotificationService;
}());
export { NotificationService };
