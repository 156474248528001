import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { Constants } from '../../constants/constants';
import { LoaderService } from './loader.service';

@Injectable()
export class RequestService {
  private accountRequestApiUrl = `${Constants.accountRequestApiUrl}/accounts`;
  private accountHistoryRequestApiUrl = `${Constants.accountRequestApiUrl}/accounthistories`;
  // private projectApiUrl = `${Constants.projectApiUrl}`;
  private headers = new Headers({
    'Content-Type': 'application/json',
    'API_KEY': Constants.apiKey
  });

  constructor(
    private _http: Http,
    private _loaderService: LoaderService
  ) { }

  private updateLoader(loading: boolean) {
    setTimeout(() => {
      this._loaderService.updateLoadingStatus(loading);
    }, 0);
  }

  public createAccount(account) {
    this.updateLoader(true);
    return this._http.post(this.accountRequestApiUrl, JSON.stringify(account), { headers: this.headers })
      .map(response => {
        this.updateLoader(false);
        return response.json();
      })
      .catch(error => {
        this.updateLoader(false);
        return Observable.throw(error);
      });
  }

  public getAccounts() {
    this.updateLoader(true);
    return this._http.get(this.accountRequestApiUrl, { headers: this.headers })
      .map(response => {
        this.updateLoader(false);
        return response.json();
      })
      .catch(error => {
        this.updateLoader(false);
        return Observable.throw(error);
      });
  }

  public getAccountByUserEmail(userEmail) {
    this.updateLoader(true);
    return this._http.get(`${this.accountRequestApiUrl}?userEmail=${userEmail}`, { headers: this.headers })
      .map(response => {
        this.updateLoader(false);
        return response.json();
      })
      .catch(error => {
        this.updateLoader(false);
        return Observable.throw(error);
      });
  }

  public getAccountByToken(token) {
    this.updateLoader(true);
    return this._http.get(`${this.accountRequestApiUrl}?token=${token}`, { headers: this.headers })
      .map(response => {
        this.updateLoader(false);
        return response.json();
      })
      .catch(error => {
        this.updateLoader(false);
        return Observable.throw(error);
      });
  }

  public getAccountsByPIEmail(piEmail) {
    this.updateLoader(true);
    return this._http.get(`${this.accountRequestApiUrl}?piEmail=${piEmail}`, { headers: this.headers })
      .map(response => {
        this.updateLoader(false);
        return response.json();
      })
      .catch(error => {
        this.updateLoader(false);
        return Observable.throw(error);
      });
  }

  public getAccountById(accountId) {
    this.updateLoader(true);
    return this._http.get(`${this.accountRequestApiUrl}/${accountId}`, { headers: this.headers })
      .map(response => {
        this.updateLoader(false);
        return response.json();
      })
      .catch(error => {
        this.updateLoader(false);
        return Observable.throw(error);
      });
  }

  public updateAccount(accountId, account) {
    this.updateLoader(true);
    return this._http.put(`${this.accountRequestApiUrl}/${accountId}`, JSON.stringify(account), { headers: this.headers })
      .map(response => {
        this.updateLoader(false);
        return response.json();
      })
      .catch(error => {
        this.updateLoader(false);
        return Observable.throw(error);
      });
  }

  public deleteAccount(accountId) {
    this.updateLoader(true);
    return this._http.delete(`${this.accountRequestApiUrl}/${accountId}`, { headers: this.headers })
      .map(response => {
        this.updateLoader(false);
        return response.json();
      })
      .catch(error => {
        this.updateLoader(false);
        return Observable.throw(error);
      });
  }

  public updateAccountHistory(account) {
    return this._http.post(`${this.accountHistoryRequestApiUrl}` , JSON.stringify(account), { headers: this.headers })
      .map(response => {
        return response.json();
      })
      .catch(error => {
        return Observable.throw(error);
      });
  }

  // public getProjectOptions(email) {
  //   return this._http.get(`${this.projectApiUrl}${email}`)
  //     .map(res => res.json())
  //     .catch((err: any) => Observable.throw(err.json().err || 'Server error'));
  // }

}
