import { Component, OnInit, Input, ViewChild, ViewChildren, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as moment from 'moment';

import { DirectorySearchService } from '../../services/directory-search.service';
import { EmailService } from '../../services/email.service';
import { LoaderService } from '../../services/loader.service';
import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../services/notification.service';
import { RequestService } from '../../services/request.service';
import { SystemTypeService } from '../../services/system-type.service';

import { Constants } from '../../../constants/constants';

import { DirectoryResult } from '../../../models/directoryResult';
import { Email } from '../../../models/email';
import { Account } from '../../../models/account';
import { User } from '../../../models/user';
import { toArray } from 'rxjs/operator/toArray';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-reset-form',
  templateUrl: './reset-form.component.html',
  styleUrls: ['./reset-form.component.css']
})
export class ResetFormComponent implements OnInit {
  public account: any = {};
  private user: User;

  private existingEmail = false;
  public formErrors: Array<string> = [];
  private passwordConfirmation;
  public permissionDenied = false;
  public requestSubmitted = false;
  private today = moment().format('MM/DD/YYYY');
  private token;
  private userEmail;
  private username;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _directorySearchService: DirectorySearchService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _emailService: EmailService,
    private _loginService: LoginService,
    private _loaderService: LoaderService,
    private _notificationService: NotificationService,
    private _requestService: RequestService,
    private _router: Router
  ) { }

  ngOnInit() {
    // Get token from url & pass to getAccountInfo method
    this._activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      this.token = paramMap.get('token');
      console.log('token', this.token);

      this.getAccountInfo(this.token);
    });
  }

  private toggleLoader(loading: boolean) {
    this._loaderService.updateLoadingStatus(loading);
  }

  private createNotification(notificationType: string, notificationTitle: string, notificationMessage: string) {
    switch (notificationType) {
      case 'success':
        this._notificationService.createSuccessNotification(notificationTitle, notificationMessage);
        break;
      case 'error':
        this._notificationService.createErrorNotification(notificationTitle, notificationMessage);
        break;
      default:
        break;
    }
  }

  private getAccountInfo(token) {
    this._requestService.getAccountByToken(token).subscribe(
      res => {
        console.log('account info', res);

        // If token exists show reset form
        if (res.length > 0) {
          this.account = res[0];
        } else {
          // Hide reset form and show permissionDenied message
          this.permissionDenied = true;
        }
      },
      error => {
        console.log('get account by id error: ', error);
      }
    );

  }

  private validateForm(account: Account) {
    this.formErrors = [];

    if (!account.Password) {
      this.formErrors.push('password');
      return false;
    }
    if (!account.Password.match(/^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{10,32}$/)) {
      this.formErrors.push('passwordRules');
      return false;
    }
    if (this.passwordConfirmation !== account.Password) {
      this.formErrors.push('passwordConfirmation');
      return false;
    }
    if (!this.formErrors.length) {
      return true;
    }
  }

  public submitReset() {
    const valid = this.validateForm(this.account);

    if (valid) {
      // Remove token
      // Update account with reset status
      this.account.Token = null;
      this.account.UpdateDate = this.today;
      this.account.SubmissionStatus = Constants.submissionStatuses.reset;

      this._requestService.updateAccount(this.account.Id, this.account).subscribe(
        res => {
          // Remove form and show submitted message
          this.requestSubmitted = true;
        },
        err => {
          console.log('submit request error: ', err);
        }
      );
    }

  }

}
