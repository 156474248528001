<div class="reset-container">
  <div class="ui middle aligned centered grid">
    <div class="column">
      <br>
      <br>
      <div *ngIf="requestSubmitted" class="ui container">
        <h1 class="ui left aligned header">Password Reset Request</h1>
        <span>Your request to reset your password has been submitted. You will receive a confirmation email when the password has been updated.</span>
        <br>
        <br>
        <span>Below are helpful links to requested systems:</span>
        <br>
        <br>
        <a href='https://ursa.research.gsu.edu/iacuc/'>IACUC</a>
        <br>
        <a href='https://ursa.research.gsu.edu/human-subjects/'>IRB</a>
        <br>
        <a href='https://help.rs.gsu.edu/display/PD/CDER'>CDER</a>
        <br>
        <a href='https://portal.clubhouse.gsu.edu'>Recovery Support Portal</a>
        <br>
        <a href='https://mmpvae.gsu.edu/'>MMPVAE</a>
      </div>
      <div *ngIf="permissionDenied" class="ui container">
        <h1 class="ui left aligned header">Password reset request not found</h1>
        <span>This password reset link has expired. Please check your inbox for the most recent email.</span>
      </div>
      <div *ngIf="!permissionDenied">
        <div *ngIf="!requestSubmitted" class="ui container">
          <h1 class="ui left aligned header">Password Reset Request</h1>
          <br>
          <div class="ui form">
            <div class="field">
              <label>Username</label>
              <input class="ui disabled field" 
                name="account.Username" 
                [(ngModel)]="account.Username" 
                [ngModelOptions]="{standalone: true}" 
                type="text">
            </div>
            <div class="ui divider header-divider"></div>
            <label class="form-error">Your new password must meet the following requirements:</label>
            <div class="ui mini bulleted list form-error">
              <div class="item">10 - 32 characters in length</div>
              <div class="item">must start with a letter</div>
              <div class="item">previously used passwords are not allowed</div>
              <div class="item">at least one upper case letter</div>
              <div class="item">at least one lower case letter</div>
              <div class="item">at least one number</div>
              <div class="item">must not contain these special characters: @ / () " ' `</div>
              <div class="item">passwords expire every 120 days</div>
            </div>
            <br>
            <div class="required field">
              <label>Password</label>
              <input name="account.Password" 
                [(ngModel)]="account.Password" 
                [ngModelOptions]="{standalone: true}" 
                type="password" 
                placeholder="Enter New Password">
              <div *ngIf="formErrors.indexOf('password') >= 0" class="form-error">This field is required</div>
              <div *ngIf="formErrors.indexOf('passwordRules') >= 0" class="form-error">Your password must follow the above requirements</div>
            </div>
            <div class="required field">
              <label>Confirm Password</label>
              <input name="passwordConfirmation" 
                [(ngModel)]="passwordConfirmation" 
                [ngModelOptions]="{standalone: true}" 
                type="password"
                placeholder="Confirm New Password">
              <div *ngIf="formErrors.indexOf('passwordConfirmation') >= 0" class="form-error">Passwords must match</div>
            </div>
            <br>
            <div class="ui divider"></div>
            <br>
            <button (click)="submitReset()" class="ui button primary">Submit</button>
            <br>
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>
</div>
