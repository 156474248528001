<sui-modal [isClosable]="true" (dismissed)="closeModal()" #modal>
  <sui-dimmer class="page inverted" [isDimmed]="isLoading">
    <div class="ui text loader">Loading</div>
  </sui-dimmer>
  <div class="header">Directory Search</div>
  <div class="content">
    <div class="ui small form">
      <div class="flex ui grid">
        <div class="fourteen wide column">
          <div class="field">
            <label>Search the GSU Directory</label>
            <input (keyup.enter)="searchAccounts()" [(ngModel)]="searchTerm" type="text"
              placeholder="Search by name or CampusID">
          </div>
        </div>
        <div class="two wide column">
          <button (click)="searchAccounts()" class="circular ui icon button">
            <i class="icon search"></i>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="searchMessage" class="message-container">
      <div class="ui divider"></div>
      <div class="ui negative message">
        <div>
          <p><strong>No account found using the above criteria:</strong></p>
          <ul>
            <li>• Check the spelling.</li>
            <li>• Try the first name or the last name only.</li>
            <li>• Try the CampusID.</li><br>
            <li>• <strong>If no account is found</strong>, have the person submit a <a
                href="https://gsutech.service-now.com/sp#request" target="_blank" style="font-weight: bold;">Help Desk
                ticket</a> requesting HR to add their account to
              the GSU Directory.</li>
            <li>• <strong>If the incorrect email is listed in the results</strong>, have the person submit a <a
                href="https://gsutech.service-now.com/sp#request" target="_blank" style="font-weight: bold;">Help Desk
                ticket</a> requesting HR to update the email
              address in the GSU Directory.</li>
          </ul>
        </div>
      </div>
    </div>
    <div [class.scroll]="searchResults.length > 6" class="search-results-container" *ngIf="searchResults">
      <div class="ui divider"></div>
      <div class="ui header">Search Results</div>
      <table class="ui small compact table">
        <thead>
          <tr>
            <th>Name</th>
            <th>E-mail Address</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of searchResults">
            <td (click)="modal.approve(selectUser(result))" class="link">{{result.displayName}}</td>
            <td>{{result.mail}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</sui-modal>
