import { OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DirectorySearchService } from '../../services/directory-search.service';
import { EmailService } from '../../services/email.service';
import { LoaderService } from '../../services/loader.service';
import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../services/notification.service';
import { RequestService } from '../../services/request.service';
import { SystemTypeService } from '../../services/system-type.service';
import { Constants } from '../../../constants/constants';
var UserAccountComponent = /** @class */ (function () {
    function UserAccountComponent(_activatedRoute, _directorySearchService, _changeDetectorRef, _emailService, _loaderService, _loginService, _notificationService, _requestService, _router, _systemTypeService) {
        this._activatedRoute = _activatedRoute;
        this._directorySearchService = _directorySearchService;
        this._changeDetectorRef = _changeDetectorRef;
        this._emailService = _emailService;
        this._loaderService = _loaderService;
        this._loginService = _loginService;
        this._notificationService = _notificationService;
        this._requestService = _requestService;
        this._router = _router;
        this._systemTypeService = _systemTypeService;
        this.account = {};
        // public projectOptions = [];
        this.systems = {};
        this.system = {};
        this.systemOptions = [];
        this.accountActivated = false;
        this.accountExists = false;
        this.approved = false;
        this.formErrors = [];
        this.showAdminButtons = false;
        this.showDenialReason = false;
        this.selectedCheckboxes = [];
        this.today = moment().format('MM/DD/YYYY');
        this.updatePassword = false;
        this.clubhouseUser = true;
    }
    UserAccountComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user = this._loginService.getUser();
        this.userEmail = this._loginService.getUserEmail();
        this.userRole = this._loginService.getUserRole();
        this._systemTypeService.getSystemTypes().subscribe(function (data) {
            _this.systemOptions = data;
        });
        this.showFormInfo();
    };
    UserAccountComponent.prototype.toggleLoader = function (loading) {
        this._loaderService.updateLoadingStatus(loading);
    };
    UserAccountComponent.prototype.toggleChangePasswordModal = function () {
        this.changePasswordModalVisible = !this.changePasswordModalVisible;
    };
    UserAccountComponent.prototype.showFormInfo = function () {
        var _this = this;
        this._requestService.getAccountByUserEmail(this.userEmail).subscribe(function (res) {
            // If account not activated yet display
            if (res.length > 0) {
                _this.account = res[0];
                // If account created and temporary password not been updated yet
                if (_this.account.SubmissionStatus === Constants.submissionStatuses.created) {
                    _this.updatePassword = true;
                }
                // System checkboxes
                var newJson = _this.account.System.replace(/([a-zA-Z0-9]+?):/g, '"$1":');
                newJson = newJson.replace(/'/g, '"');
                _this.systemOptions = JSON.parse(newJson);
            }
            else {
                _this._directorySearchService.searchUsers(_this.user.firstname + ' ' + _this.user.lastname).subscribe(function (response) {
                    var accounts = response;
                    if (Array.isArray(accounts) && accounts.length > 0) {
                        _this.searchResults = accounts.filter(function (result) {
                            _this.account.FirstName = _this.user.firstname;
                            _this.account.LastName = _this.user.lastname;
                            _this.account.Email = result.mail;
                            return result;
                        });
                    }
                    else {
                        // If no account
                        _this.account = {};
                    }
                }, function (error) {
                    console.log('search by name error: ', error);
                    // If no account
                    _this.account = {};
                });
            }
        }, function (error) {
            console.log('get account by id error: ', error);
        });
    };
    UserAccountComponent.prototype.createNotification = function (notificationType, notificationTitle, notificationMessage) {
        switch (notificationType) {
            case 'success':
                this._notificationService.createSuccessNotification(notificationTitle, notificationMessage);
                break;
            case 'error':
                this._notificationService.createErrorNotification(notificationTitle, notificationMessage);
                break;
            default:
                break;
        }
    };
    UserAccountComponent.prototype.validateForm = function (account) {
        this.formErrors = [];
        if (!this.password) {
            this.formErrors.push('password');
            return false;
        }
        if (!this.password.match(/^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{10,32}$/)) {
            this.formErrors.push('passwordRules');
            return false;
        }
        if (this.passwordConfirmation !== this.password) {
            this.formErrors.push('passwordConfirmation');
            return false;
        }
        if (!this.formErrors.length) {
            return true;
        }
    };
    UserAccountComponent.prototype.getCheckedSystem = function () {
        var _this = this;
        var systems = JSON.parse(this.account.System);
        console.log(systems);
        systems.forEach(function (system) {
            console.log(systems);
            if (system.Name === 'MMPVAE' && system.checked === true) {
                _this.checked = 'mmpvae';
            }
            if (system.Name === 'Clubhouse Support Portal' && system.checked === true) {
                _this.checked = 'clubhouse';
            }
        });
    };
    UserAccountComponent.prototype.updateAccount = function () {
        var _this = this;
        var valid = this.validateForm(this.account);
        if (valid) {
            this.getCheckedSystem();
            var systemType_1 = this.checked;
            this.account.SubmissionStatus = Constants.submissionStatuses.updated;
            this.account.Password = this.password;
            this.account.System = JSON.stringify(this.systemOptions);
            this.account.UpdateDate = this.today;
            this._requestService.updateAccount(this.account.Id, this.account).subscribe(function (res) {
                _this.updateAccountHistory(_this.account);
                // Notify MMPVAE admin of request
                if (systemType_1 === 'mmpvae') {
                    _this._emailService.createAdminNotificationAccountActivated(_this.account.Id, _this.account, 'mmpvae').subscribe(function (data) {
                    });
                }
                _this.createNotification('success', 'Password Updated', 'Your temporary password was successfully updated. You will receive a confirmation email when your account has been activated.');
                _this.updatePassword = false; // hide section
                _this.accountActivated = true; // show section
                _this.showFormInfo();
            }, function (err) {
                console.log('update request error: ', err);
                _this.createNotification('error', 'Password Updated', 'An error has occurred');
            });
        }
    };
    UserAccountComponent.prototype.changePassword = function () {
        var _this = this;
        var valid = this.validateForm(this.account);
        if (valid) {
            // Create account with reset status
            this.account.FirstName = this.account.FirstName;
            this.account.LastName = this.account.LastName;
            this.account.Email = this.account.Email;
            this.account.Username = this.user.username;
            this.account.Password = this.password;
            this.account.UpdateDate = this.today;
            this.account.SubmissionStatus = Constants.submissionStatuses.reset;
            this._requestService.createAccount(this.account).subscribe(function (res) {
                _this.updateAccountHistory(res);
                _this.toggleChangePasswordModal();
                _this.createNotification('success', 'Change Password Request', 'Your request to change your password has been submitted. You will receive a confirmation email when the password has been updated.');
            }, function (err) {
                console.log('update request error: ', err);
                _this.createNotification('error', 'Change Password Request', 'An error has occurred');
            });
        }
    };
    UserAccountComponent.prototype.updateAccountHistory = function (account) {
        this.account.AccountId = this.account.Id;
        this._requestService.updateAccountHistory(this.account).subscribe(function (data) {
        }, function (err) {
            console.log('save account history error: ', err);
        });
    };
    return UserAccountComponent;
}());
export { UserAccountComponent };
