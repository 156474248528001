import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { LoaderService } from './loader.service';

import { Constants } from '../../constants/constants';

import { User } from '../../models/user';

@Injectable()
export class LoginService {
  public loggedInUser = new Subject<boolean>();
  public loggedInUserUpdate = this.loggedInUser.asObservable();
  private apiUrl = Constants.loginAPIUrl;
  private headers = new Headers({
    'Content-Type': 'application/json'
  });
  private newRoles = [];
  private role: string;
  private user: User;

  constructor(
    private _http: Http,
    private _router: Router,
    private _loaderService: LoaderService
  ) { }

  public login(username, password) {
    this.updateLoader(true);
    const body = {
      username,
      password
    };

    return this._http.post(this.apiUrl, JSON.stringify(body), { headers: this.headers })
      .map(
        response => {
          this.user = response.json();

          this.setUserSessionStorage(this.user);
          this.updateLoggedInStatus(this.user);
          this.updateLoader(false);
          return this.user;
        }
      )
      .catch(
        error => {
          this.updateLoader(false);
          return Observable.throw(error);
        }
      );
  }

  private updateLoader(loading: boolean) {
    this._loaderService.updateLoadingStatus(loading);
  }

  public isLoggedIn(): boolean {
    const user = this.getUserSessionStorage();

    if (user) {
      return true;
    } else {
      return false;
    }
  }

  public getUserSessionStorage(): any {
    this.updateLoader(true);
    const user = localStorage.getItem('user');

    if (user) {
      this.user = JSON.parse(user);
      this.updateLoggedInStatus(this.user);
      this.updateLoader(false);
      return this.user;
    } else {
      this.updateLoader(false);
      return null;
    }
  }

  private setUserSessionStorage(user: User) {
    if (!user.error) {
      const firstname = JSON.parse(JSON.stringify(user.firstname)),
            email = JSON.parse(JSON.stringify(user.email));

        // String to array then for loop to get role
      let roles = this.role = user.groups;

      // Split string
      roles = roles ? roles.split(',') : [];

      for (const i of roles) {
        // tslint:disable-next-line:no-unused-expression
        i && this.newRoles.push(i);

        roles = this.newRoles;
      }

      // if roles
      let userRole;

      if (roles.length > 0) {
        for (const role of roles) {
          switch (role) {
            case 'AM-Admins':
              userRole = 'admin';
              localStorage.setItem('userrole', userRole);
              break;
            default:
              userRole = 'general';
              localStorage.setItem('userrole', userRole);
              break;
          }

          if (userRole === 'admin') {
            break;
          }

        }
      } else {
        // If no groups assign General
        userRole = 'general';
        localStorage.setItem('userrole', userRole);
      }

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('firstname', firstname);
      localStorage.setItem('email', email);

    }

  }

  public getUser() {
    return this.user;
  }

  public getUserEmail() {
    return localStorage.getItem('email');
  }

  public getUserName() {
    return localStorage.getItem('firstname');
  }

  public getUserRole() {
    return localStorage.getItem('userrole');
  }

  public updateLoggedInStatus(user: User) {
    let loggedIn;

    if (user === undefined) {
      loggedIn = false;

      this.loggedInUser.next(loggedIn);
    }
    if (user !== undefined) {
      loggedIn = this.isLoggedIn ? true : false;

      this.loggedInUser.next(loggedIn);
    }

  }

  public logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('firstname');
    localStorage.removeItem('email');
    localStorage.removeItem('adminemail');
    localStorage.removeItem('userrole');

    this.user = undefined;
    this.loggedInUser.next(false);
    this._router.navigateByUrl('login');
  }

}
