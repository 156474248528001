export class Email {
  fromEmail: string;
  toEmail: string;
  subject: string;
  messageType: string;
  submissionId: string;
  isImportant: boolean;
  system: string;
  applicant: string;
  approver: string;
  deniedBy: string;
  denialReason: string;
  date: string;
  username: string;
  token: string;
}
