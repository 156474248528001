import { Subject } from 'rxjs/Subject';
var LoaderService = /** @class */ (function () {
    function LoaderService() {
        this.loading = new Subject();
        this.directorySearchLoading = new Subject();
        this.apiCall = new Subject();
        this.loadingUpdate = this.loading.asObservable();
        this.directoryLoadingUpdate = this.directorySearchLoading.asObservable();
        this.apiCallUpdate = this.apiCall.asObservable();
    }
    LoaderService.prototype.updateLoadingStatus = function (loading) {
        this.loading.next(loading);
    };
    LoaderService.prototype.updateDirectorySearchLoadingStatus = function (loading) {
        this.directorySearchLoading.next(loading);
    };
    LoaderService.prototype.updateApiWaitingStatus = function (waiting) {
        this.apiCall.next(waiting);
    };
    LoaderService.prototype.getMobileStatus = function () {
        if (this.mobileDevice === undefined) {
            this.mobileDevice = (window.innerWidth <= 800) ? true : false;
            return this.mobileDevice;
        }
        else {
            return this.mobileDevice;
        }
    };
    return LoaderService;
}());
export { LoaderService };
