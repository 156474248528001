import { Router } from '@angular/router';
import { LoginService } from './login.service';
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(_router, _loginService) {
        this._router = _router;
        this._loginService = _loginService;
    }
    AuthGuardService.prototype.canActivate = function (route, state) {
        var isLoggedIn = this._loginService.isLoggedIn();
        if (isLoggedIn) {
            return true;
        }
        else {
            this._router.navigate(['/login'], {
                queryParams: {
                    return: state.url
                }
            });
            return false;
        }
    };
    return AuthGuardService;
}());
export { AuthGuardService };
