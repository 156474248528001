import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent implements OnInit {
  @Output() public closeDeleteModal = new EventEmitter();

  public deletedAccount;

  constructor() { }

  ngOnInit() {
  }

  public closeModal(deletedAccount?) {
    const emittedData = deletedAccount ? deletedAccount : true;

    this.closeDeleteModal.emit(emittedData);
  }

}
