import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Constants } from '../../constants/constants';
import { Email } from '../../models/email';
var EmailService = /** @class */ (function () {
    function EmailService(_http) {
        this._http = _http;
        this.emailApiUrl = "" + Constants.emailApiUrl;
        this.headers = new Headers({
            'Content-Type': 'application/json'
        });
        this.email = new Email();
    }
    EmailService.prototype.createNotificationSubmitted = function (id, submission, systemType) {
        var headers = new Headers({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:4200'
        });
        if (systemType === 'mmpvae') {
            // Notify MMPVAE Admin
            this.email.fromEmail = 'Account Management - MMPVAE Admin';
            this.email.approver = submission.PI;
            this.email.toEmail = submission.PIEmail + ',amurray36@gsu.edu';
            this.email.messageType = 'pending';
            this.email.subject = 'Request Pending Approval';
            this.email.submissionId = id;
            this.email.isImportant = true;
            this.email.applicant = submission.FirstName + ' ' + submission.LastName;
            return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers: headers })
                .map(function (res) { return res.json(); })
                .catch(function (err) { return Observable.throw(err.json().err || 'Server error'); });
        }
        else {
            this.email.fromEmail = 'Account Management';
            this.email.approver = submission.PI;
            this.email.toEmail = submission.PIEmail;
            this.email.messageType = 'pending';
            this.email.subject = 'Request Pending Approval';
            this.email.submissionId = id;
            this.email.isImportant = true;
            this.email.applicant = submission.FirstName + ' ' + submission.LastName;
            return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers: headers })
                .map(function (res) { return res.json(); })
                .catch(function (err) { return Observable.throw(err.json().err || 'Server error'); });
        }
    };
    EmailService.prototype.createAdminNotificationSubmitted = function (id, submission, systemType) {
        var headers = new Headers({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:4200'
        });
        if (systemType === 'cder') {
            // Notify CDER Admin
            this.email.fromEmail = 'Account Management - CDER';
            this.email.approver = submission.PI;
            this.email.toEmail = 'hpc@rs.gsu.edu';
            this.email.messageType = 'pendingAdmin';
            this.email.subject = 'Account Requested - CDER';
            this.email.submissionId = id;
            this.email.isImportant = true;
            this.email.applicant = submission.FirstName + ' ' + submission.LastName;
            return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers: headers })
                .map(function (res) { return res.json(); })
                .catch(function (err) { return Observable.throw(err.json().err || 'Server error'); });
        }
        else {
            this.email.fromEmail = 'Account Management';
            this.email.approver = submission.PI;
            this.email.toEmail = 'sbesozzi@gsu.edu,ylukinov@gsu.edu';
            this.email.messageType = 'pendingAdmin';
            this.email.subject = 'Account Requested - Admin';
            this.email.submissionId = id;
            this.email.isImportant = true;
            this.email.applicant = submission.FirstName + ' ' + submission.LastName;
            return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers: headers })
                .map(function (res) { return res.json(); })
                .catch(function (err) { return Observable.throw(err.json().err || 'Server error'); });
        }
    };
    EmailService.prototype.createNotificationApproved = function (id, submission, systemType) {
        var headers = new Headers({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:4200'
        });
        if (systemType === 'mmpvae') {
            // Notify MMPVAE Admin
            this.email.fromEmail = 'Account Management - MMPVAE Admin';
            this.email.toEmail = submission.Email + ',amurray36@gsu.edu';
            this.email.messageType = 'approved';
            this.email.subject = 'Request Approved';
            this.email.submissionId = id;
            this.email.isImportant = true;
            this.email.applicant = submission.FirstName + ' ' + submission.LastName;
            this.email.approver = submission.ApprovedBy;
            console.log('mmpvae', this.email);
            return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers: headers })
                .map(function (res) { return res.json(); })
                .catch(function (err) { return Observable.throw(err.json().err || 'Server error'); });
        }
        else {
            this.email.fromEmail = 'Account Management';
            this.email.toEmail = submission.Email;
            this.email.messageType = 'approved';
            this.email.subject = 'Request Approved';
            this.email.submissionId = id;
            this.email.isImportant = true;
            this.email.applicant = submission.FirstName + ' ' + submission.LastName;
            this.email.approver = submission.ApprovedBy;
            return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers: headers })
                .map(function (res) { return res.json(); })
                .catch(function (err) { return Observable.throw(err.json().err || 'Server error'); });
        }
    };
    EmailService.prototype.createNotificationDenied = function (id, submission, systemType) {
        var headers = new Headers({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:4200'
        });
        if (systemType === 'mmpvae') {
            // Notify MMPVAE Admin
            this.email.fromEmail = 'Account Management - MMPVAE Admin';
            this.email.toEmail = submission.Email + ',amurray36@gsu.edu';
            this.email.messageType = 'denied';
            this.email.subject = 'Request Denied';
            this.email.submissionId = id;
            this.email.isImportant = true;
            this.email.denialReason = submission.DenialReason;
            this.email.applicant = submission.FirstName + ' ' + submission.LastName;
            this.email.approver = submission.ApprovedBy;
            return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers: headers })
                .map(function (res) { return res.json(); })
                .catch(function (err) { return Observable.throw(err.json().err || 'Server error'); });
        }
        else {
            this.email.fromEmail = 'Account Management';
            this.email.toEmail = submission.Email;
            this.email.messageType = 'denied';
            this.email.subject = 'Request Denied';
            this.email.submissionId = id;
            this.email.isImportant = true;
            this.email.denialReason = submission.DenialReason;
            this.email.applicant = submission.FirstName + ' ' + submission.LastName;
            this.email.approver = submission.ApprovedBy;
            return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers: headers })
                .map(function (res) { return res.json(); })
                .catch(function (err) { return Observable.throw(err.json().err || 'Server error'); });
        }
    };
    EmailService.prototype.createAdminNotificationAccountActivated = function (id, submission, systemType) {
        var headers = new Headers({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:4200'
        });
        if (systemType === 'mmpvae') {
            // Notify MMPVAE Admin
            this.email.fromEmail = 'Account Management - MMPVAE Admin';
            this.email.toEmail = 'amurray36@gsu.edu';
            this.email.messageType = 'activated';
            this.email.subject = 'Account Activated';
            this.email.submissionId = id;
            this.email.isImportant = true;
            this.email.denialReason = submission.DenialReason;
            this.email.applicant = submission.FirstName + ' ' + submission.LastName;
            this.email.approver = submission.ApprovedBy;
            return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers: headers })
                .map(function (res) { return res.json(); })
                .catch(function (err) { return Observable.throw(err.json().err || 'Server error'); });
        }
        else {
        }
    };
    EmailService.prototype.createNotificationUsernameReminder = function (email, username) {
        var headers = new Headers({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:4200'
        });
        this.email.fromEmail = 'Account Management';
        this.email.toEmail = email;
        this.email.messageType = 'username';
        this.email.subject = 'Username Reminder';
        this.email.isImportant = true;
        this.email.username = username;
        return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers: headers })
            .map(function (res) { return res.json(); })
            .catch(function (err) { return Observable.throw(err.json().err || 'Server error'); });
    };
    EmailService.prototype.createNotificationForgotPassword = function (email, username, token) {
        var headers = new Headers({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://localhost:4200'
        });
        this.email.fromEmail = 'Account Management';
        this.email.toEmail = email;
        this.email.messageType = 'forgot';
        this.email.subject = 'Forgot Password';
        this.email.isImportant = true;
        this.email.username = username;
        this.email.token = token;
        return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers: headers })
            .map(function (res) { return res.json(); })
            .catch(function (err) { return Observable.throw(err.json().err || 'Server error'); });
    };
    return EmailService;
}());
export { EmailService };
