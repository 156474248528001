import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import { Constants } from '../../../constants/constants';

import { DirectorySearchService } from '../../services/directory-search.service';
import { EmailService } from '../../services/email.service';
import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../services/notification.service';
import { RequestService } from '../../services/request.service';
import { TokenService } from '../../services/token.service';

import { Account } from '../../../models/account';
import { DirectoryResult } from '../../../models/directoryResult';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  private account: any = {};

  private email;
  public errorMessage: string;
  private existingEmail = false;
  private existingEmails = [];
  public formErrors: Array<string> = [];
  private gsuEmail = false;
  public isLoading;
  public isLoggedIn = this._loginService.isLoggedIn();
  public matchingEmail;
  public passwordResetModalVisible: boolean;
  public password: string;
  private redirectRoute;
  private routeSubscription;
  public searchMessage: string;
  public searchResults: Array<DirectoryResult>;
  private today = moment().format('MM/DD/YYYY');
  public username: string;
  public usernameReminderModalVisible: boolean;

  constructor(
    private _directorySearchService: DirectorySearchService,
    private _emailService: EmailService,
    private _loginService: LoginService,
    private _notificationService: NotificationService,
    private _requestService: RequestService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _tokenService: TokenService
  ) { }

  public ngOnInit() {
    this.routeSubscription = this._route.queryParams.subscribe(params => {
      this.redirectRoute = params['return'] || '';
    });
  }

  public ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  private createNotification(notificationType: string, notificationTitle: string, notificationMessage: string) {
    switch (notificationType) {
      case 'success':
        this._notificationService.createSuccessNotification(notificationTitle, notificationMessage);
        break;
      case 'error':
        this._notificationService.createErrorNotification(notificationTitle, notificationMessage);
        break;
      default:
        break;
    }
  }

  public login() {
    this.errorMessage = undefined;
    this._loginService.login(this.username, this.password).subscribe(
      response => {
        if (response.error) {
          this.errorMessage = response.error;
        } else {
          // do the routing logic updates here
          if (this.redirectRoute === '') {
            this._router.navigateByUrl('user/user-account');
          } else {
            this._router.navigateByUrl(this.redirectRoute);
          }

        }
      },
      error => {
        console.log('login error: ', error);
      }
    );
  }

  public togglePasswordResetModal() {
    this.passwordResetModalVisible = !this.passwordResetModalVisible;
  }

  public toggleUsernameReminderModal() {
    this.usernameReminderModalVisible = !this.usernameReminderModalVisible;
  }

  public checkEmail(type) {
    // Check if user exists in AD by email
    if (type === 'password') {
      this.submitPasswordReset();
    }
    if (type === 'username') {
      this.submitUsernameReminder();
    }
  }

  private validateForm(email) {
    this.formErrors = [];

    // If no email
    if (!this.email) {
      this.formErrors.push('email');
      return false;
    }

    // if (this.email && this.existingEmail === false) {
    //   this.formErrors.push('emailDoesntExist');
    //   return false;
    // }

    if (this.email.includes('gsu.edu', 'student.gsu.edu')) {
      console.log('gsu email');
      this.gsuEmail = true;
      this.formErrors.push('emailGSU');

      return false;
    }

    if (!this.formErrors.length) {
      return true;
    }

  }

  private submitPasswordReset() {
    const valid = this.validateForm(this.email);

    if (valid) {
      // Check if reset already requested
      this._requestService.getAccountByUserEmail(this.email).subscribe(
        res => {
          if (res.length >= 1 && res[res.length - 1].SubmissionStatus === 'Reset Requested') {
            // Resend email with token
            this.isLoading = true;
            this.togglePasswordResetModal();

            this._emailService.createNotificationForgotPassword(this.email, res[res.length - 1].Username, res[res.length - 1].Token).subscribe((res3) => {
              this.isLoading = false;

              this.createNotification('success', 'Password Reset Requested', 'We will send you an email with a link to reset your password.');
            });

          } else if (res.length >= 1 && res[res.length - 1].SubmissionStatus !== 'Reset Requested') {
            this.isLoading = true;
            this.togglePasswordResetModal();

            // Create new request if no previous reset request
            // Get random token
            this._tokenService.getToken(16).subscribe((res2) => {
              const token = res2.access_token;

              // Create account
              this.account.Username = res[res.length - 1].Username;
              this.account.Email = this.email;
              this.account.Token = token;
              this.account.UpdateDate = this.today;
              this.account.SubmissionStatus = Constants.submissionStatuses.resetRequested;

              this._requestService.createAccount(this.account).subscribe(
                res3 => {
                  this.updateAccountHistory(this.account);

                  // Pass token to email object where it is appended to reset form route
                  this._emailService.createNotificationForgotPassword(this.email, res[res.length - 1].Username, token).subscribe((res4) => {
                    this.isLoading = false;
                    this.createNotification('success', 'Password Reset Requested', 'We will send you an email with a link to reset your password.');
                  });
                },
                err => {
                  console.log('update request error: ', err);
                  this.createNotification('error', 'Password Reset Email', 'An error has occurred');
                }
              );

            },
              err => {
                console.log('update request error: ', err);
                this.createNotification('error', 'Password Reset Email', 'An error has occurred');
              }
            );
          } else {
            // no account found
            this.createNotification('success', 'No Account Found', 'There is not an account associated with this email. Please create an account.');
          }
        });
    }
  }

  private updateAccountHistory(account) {
    account.AccountId = account.Id;

    this._requestService.updateAccountHistory(account).subscribe((data) => {

    },
      err => {
        console.log('save account history error: ', err);
      });
  }

  private submitUsernameReminder() {
    const valid = this.validateForm(this.email);

    if (valid) {
      const splitEmail = this.email.split('@'),
        username = splitEmail[0];

      // Notify PI of request
      this._emailService.createNotificationUsernameReminder(this.email, username).subscribe((data) => {
        this.toggleUsernameReminderModal();
        this.createNotification('success', 'Username Reminder Email Sent', 'Request email successfully sent');
      });

    }
  }

  private logout() {
    this.isLoggedIn = false;
    this._loginService.logout();
  }

}
