<div [class.mobile-container]="mobileDevice" [class.user-container]="!mobileDevice" class="user-container">
  <sui-tabset>
    <div [class.top]="!mobileDevice" 
      [class.attached]="!mobileDevice" 
      [class.tabular]="!mobileDevice" 
      [class.secondary]="mobileDevice"
      [class.pointing]="mobileDevice" 
      class="ui menu">
      <a *ngIf="generalUser || adminUser" 
        class="item" 
        suiTabHeader="1" 
        [isActive]="url.indexOf('user-account') >= 0" 
        routerLink='user-account' 
        routerLinkActive="active">
        My Account</a>
      <a *ngIf="generalUser" 
        class="item" 
        suiTabHeader="2" 
        [isActive]="url.indexOf('submissions') >= 0" 
        routerLink='submissions'>
        Requests</a>
      <a *ngIf="adminUser" 
        class="item" 
        suiTabHeader="3" 
        [isActive]="url.indexOf('all-submissions') >= 0" 
        routerLink='all-submissions'>
        Requests</a>
      <a *ngIf="adminUser" 
        class="item" 
        suiTabHeader="4" 
        [isActive]="url.indexOf('settings') >= 0" 
        routerLink='settings'>
        System Settings</a>
    </div>
    <div class="ui bottom attached segment" suiTabContent="1">
      <app-user-account [url]="url"></app-user-account>
    </div>
    <div class="ui bottom attached segment" suiTabContent="2">
      <app-submissions [url]="url"></app-submissions>
    </div>
    <div class="ui bottom attached segment" suiTabContent="3">
      <app-all-submissions [url]="url"></app-all-submissions>
    </div>
    <div class="ui bottom attached segment" suiTabContent="4">
      <app-settings [url]="url"></app-settings>
    </div>
  </sui-tabset>
</div>
