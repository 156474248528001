import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { LoginService } from '../services/login.service';

@Injectable()
export class ChildAuthGuardService implements CanActivateChild {

  constructor(
    protected _router: Router,
    private _loginService: LoginService,
    private _route: ActivatedRoute
  ) { }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const isLoggedIn = this._loginService.isLoggedIn(),
      userRole = this._loginService.getUserRole(),
      rooute = route.params,
      id = route.params['id'],
      generalUser = userRole === 'general' && isLoggedIn,
      adminUser = userRole === 'admin' && isLoggedIn;

    // General user
    if (generalUser && state.url === '/user/user-account' || generalUser && state.url === '/user/submissions') {
      return true;
    }

    // Admin user
    if (adminUser && state.url === '/user/user-account' || adminUser && state.url === '/user/submissions' || adminUser && state.url === '/user/all-submissions' || adminUser && state.url === '/user/settings') {
      return true;
    } else {
      this._router.navigate(['/user/user-account']);
      return false;
    }
  }

}
