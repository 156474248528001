import { OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DirectorySearchService } from '../../services/directory-search.service';
import { EmailService } from '../../services/email.service';
import { LoaderService } from '../../services/loader.service';
import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../services/notification.service';
import { RequestService } from '../../services/request.service';
import { SystemTypeService } from '../../services/system-type.service';
import { Constants } from '../../../constants/constants';
var RequestFormComponent = /** @class */ (function () {
    function RequestFormComponent(_activatedRoute, _directorySearchService, _changeDetectorRef, _emailService, _loginService, _loaderService, _notificationService, _requestService, _router, _systemTypeService) {
        this._activatedRoute = _activatedRoute;
        this._directorySearchService = _directorySearchService;
        this._changeDetectorRef = _changeDetectorRef;
        this._emailService = _emailService;
        this._loginService = _loginService;
        this._loaderService = _loaderService;
        this._notificationService = _notificationService;
        this._requestService = _requestService;
        this._router = _router;
        this._systemTypeService = _systemTypeService;
        this.account = {};
        this.projectOptions = [];
        this.systems = {};
        this.system = {};
        this.systemOptions = [];
        this.adminUser = false;
        this.approved = false;
        this.checkboxArray = [];
        this.countries = Constants.countryDropdownOptions;
        this.emailExists = false;
        this.existingEmail = false;
        this.existingEmails = [];
        this.formErrors = [];
        this.generalUser = false;
        this.guestUser = false;
        this.requestSubmitted = false;
        this.selectedCheckboxes = [];
        this.singleRequest = false;
        this.showAdminButtons = false;
        this.showApprovedMessage = false;
        this.showCDERDetails = false;
        this.showDenialReason = false;
        this.today = moment().format('MM/DD/YYYY');
    }
    RequestFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._activatedRoute.paramMap.subscribe(function (paramMap) {
            _this.accountId = paramMap.get('id');
            _this.showFormInfo(_this.accountId);
        });
        // Set user
        this._loginService.updateLoggedInStatus(this._loginService.getUser());
        this.user = this._loginService.getUser();
        this.userEmail = this._loginService.getUserEmail();
        this.userName = this._loginService.getUserName();
        this.userRole = this._loginService.getUserRole();
        // Admin user
        if (this.userRole === 'admin' || this.userRole === null) {
            this.adminUser = true;
        }
    };
    RequestFormComponent.prototype.toggleLoader = function (loading) {
        this._loaderService.updateLoadingStatus(loading);
    };
    RequestFormComponent.prototype.showSystemCheckboxes = function (account) {
        var _this = this;
        this._systemTypeService.getSystemTypes().subscribe(function (data) {
            var jsonData = [];
            // show legacy system types in activated accounts
            // else only show CDER and Recovery Support Portal
            if (account === 'noAccount') {
                jsonData = data.filter(function (system) { return system.Name !== 'Dropbox' && system.Name !== 'GSU IACUC' && system.Name !== 'GSU IRB' && system.Name !== 'MMPVAE' && system.Name !== 'SFI'; });
                // alphabetize system names
                var alphaData = jsonData.sort(function (a, b) { return a.Name.localeCompare(b.Name); });
                _this.systemOptions = alphaData;
            }
        });
    };
    RequestFormComponent.prototype.showFormInfo = function (accountId) {
        var _this = this;
        if (accountId !== null) {
            this._requestService.getAccountById(accountId).subscribe(function (res) {
                _this.account = res;
                // System checkboxes
                var newJson = _this.account.System.replace(/([a-zA-Z0-9]+?):/g, '"$1":');
                newJson = newJson.replace(/'/g, '"');
                var alphaData = JSON.parse(newJson);
                _this.systemOptions = alphaData.sort(function (a, b) { return a.Name.localeCompare(b.Name); });
                // Display country field if CDER is checked
                _this.systemOptions.forEach(function (system) {
                    if (system.Name === 'CDER' && system.checked === true) {
                        // Default Rajshekhar Sunderraman (rsunderraman@gsu.edu) as PI
                        _this.account.PI = 'Rajshekhar Sunderraman';
                        _this.account.PIEmail = 'rsunderraman@gsu.edu';
                        _this.showCDERDetails = true;
                    }
                });
                // Single request form
                _this.singleRequest = true;
                _this.showAdminButtons = true;
                // If approved
                if (_this.account.SubmissionStatus === Constants.submissionStatuses.approved || _this.account.SubmissionStatus === Constants.submissionStatuses.updated || _this.account.SubmissionStatus === Constants.submissionStatuses.denied || _this.account.SubmissionStatus === Constants.submissionStatuses.created || _this.account.SubmissionStatus === Constants.submissionStatuses.activated) {
                    _this.approved = true;
                }
                // If approved show message
                if (_this.account.SubmissionStatus === Constants.submissionStatuses.approved || _this.account.SubmissionStatus === Constants.submissionStatuses.updated || _this.account.SubmissionStatus === Constants.submissionStatuses.created || _this.account.SubmissionStatus === Constants.submissionStatuses.activated) {
                    _this.showApprovedMessage = true;
                }
                // If denied show reason
                if (_this.account.SubmissionStatus === Constants.submissionStatuses.denied && _this.account.DenialReason !== null) {
                    _this.denialReason = _this.account.DenialReason;
                    _this.showDenialReason = true;
                }
            }, function (error) {
                console.log('get submission by id error: ', error);
            });
        }
        else {
            this.showSystemCheckboxes('noAccount');
        }
    };
    RequestFormComponent.prototype.createNotification = function (notificationType, notificationTitle, notificationMessage) {
        switch (notificationType) {
            case 'success':
                this._notificationService.createSuccessNotification(notificationTitle, notificationMessage);
                break;
            case 'error':
                this._notificationService.createErrorNotification(notificationTitle, notificationMessage);
                break;
            default:
                break;
        }
    };
    RequestFormComponent.prototype.backToDashboard = function () {
        if (this.userRole === 'admin') {
            this._router.navigateByUrl('user/all-submissions');
        }
        else {
            this._router.navigateByUrl('user/submissions');
        }
    };
    RequestFormComponent.prototype.toggleDirectorySearchModal = function (selectedField) {
        // If not adminUser then disable toggle
        if (this.adminUser || !this.showCDERDetails === true) {
            this.directorySearchModalVisible = !this.directorySearchModalVisible;
            if (selectedField) {
                this.selectedField = selectedField;
            }
        }
    };
    // Search directory
    RequestFormComponent.prototype.updateFieldsWithUserInfo = function (selectedUser) {
        switch (this.selectedField) {
            case 'pi':
                this.account.PI = selectedUser.DisplayName;
                this.account.PIEmail = selectedUser.EmailAddress;
                break;
            case 'teacher':
                this.account.Teacher = selectedUser.DisplayName;
                this.account.TeacherEmail = selectedUser.EmailAddress;
                break;
            default:
                break;
        }
        this.directorySearchModalVisible = false;
    };
    RequestFormComponent.prototype.onCheckboxSelect = function (system, event) {
        if (event === true) {
            this.system = JSON.stringify(this.systemOptions);
        }
        if (system.Name === 'CDER') {
            if (system.checked === true) {
                // Default Rajshekhar Sunderraman (rsunderraman@gsu.edu) as PI
                // Disable field
                this.account.PI = 'Rajshekhar Sunderraman';
                this.account.PIEmail = 'rsunderraman@gsu.edu';
                this.showCDERDetails = true;
            }
            else if (system.checked === false) {
                this.account.PI = '';
                this.account.PIEmail = '';
                this.showCDERDetails = false;
            }
        }
        else if (system.Name === 'Recovery Support Portal') {
            if (system.checked === true) {
                // Default Kristal Davidson (kristal.davidson@dbhdd.ga.gov) as PI
                this.account.PI = 'Kristal Davidson';
                this.account.PIEmail = 'kristal.davidson@dbhdd.ga.gov';
            }
            else if (system.checked === false) {
                this.account.PI = '';
                this.account.PIEmail = '';
            }
        }
    };
    RequestFormComponent.prototype.checkEmail = function () {
        var _this = this;
        // Check if user exists by email
        var email = this.account.Email;
        console.log('email', email);
        // Search AD for email
        this._directorySearchService.searchUsers(email).subscribe(function (res) {
            console.log('res', res);
            if (res.length > 1) {
                console.log('res', res);
                _this.existingEmail = true;
                _this.submitRequest();
            }
            else {
                // Search AM for email
                _this._requestService.getAccountByUserEmail(email).subscribe(function (res2) {
                    if (res2.length >= 1) {
                        _this.existingEmail = true;
                        _this.submitRequest();
                    }
                    else {
                        _this.existingEmail = false;
                        _this.submitRequest();
                    }
                });
            }
        });
    };
    RequestFormComponent.prototype.validateForm = function (account) {
        var _this = this;
        this.formErrors = [];
        // If CDER is checked show Country dropdown
        this.systemOptions.forEach(function (system) {
            if (!account.Country && system.Name === 'CDER' && system.checked === true) {
                _this.formErrors.push('country');
                return false;
            }
        });
        if (!account.FirstName) {
            this.formErrors.push('firstName');
            return false;
        }
        if (!account.FirstName.match(/^[A-z0-9]*((-|\s)*[_A-z0-9])*$/)) {
            this.formErrors.push('firstNameRules');
            return false;
        }
        if (!account.LastName) {
            this.formErrors.push('lastName');
            return false;
        }
        if (!account.LastName.match(/^[A-z0-9]*((-|\s)*[_A-z0-9])*$/)) {
            this.formErrors.push('lastNameRules');
            return false;
        }
        if (!account.Email) {
            this.formErrors.push('email');
            return false;
        }
        if (!account.Email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            this.formErrors.push('emailCharacters');
            return false;
        }
        if (account.Email && this.existingEmail === true) {
            this.formErrors.push('emailExists');
            return false;
        }
        if (!account.Company) {
            this.formErrors.push('company');
            return false;
        }
        if (!account.PI) {
            this.formErrors.push('pi');
            return false;
        }
        if (!this.formErrors.length) {
            return true;
        }
    };
    RequestFormComponent.prototype.getCheckedSystem = function () {
        var _this = this;
        this.systemOptions.forEach(function (system) {
            if (system.Name === 'CDER' && system.checked === true) {
                _this.checked = 'cder';
            }
            if (system.Name === 'MMPVAE' && system.checked === true) {
                _this.checked = 'mmpvae';
            }
        });
    };
    RequestFormComponent.prototype.submitRequest = function () {
        var _this = this;
        var valid = this.validateForm(this.account);
        if (valid) {
            this.getCheckedSystem();
            var systemType_1 = this.checked;
            this.account.SubmissionDate = this.today;
            this.account.SubmissionStatus = Constants.submissionStatuses.pending;
            if (this.system === 'string') {
                this.account.System = this.system;
            }
            else {
                this.account.System = JSON.stringify(this.systemOptions);
            }
            this._requestService.createAccount(this.account).subscribe(function (res) {
                // // Notify PI of request
                _this._emailService.createNotificationSubmitted(res.Id, _this.account, systemType_1).subscribe(function (data) {
                });
                // Notify Admin of request
                _this._emailService.createAdminNotificationSubmitted(res.Id, _this.account, systemType_1).subscribe(function (data) {
                });
                _this.createNotification('success', 'Submitted for Approval', 'Request successfully submitted');
                // Remove form and show message
                _this.requestSubmitted = true;
                _this.accountId = res.Id;
                _this.requestedByName = res.FirstName + ' ' + res.LastName;
            }, function (err) {
                console.log('submit request error: ', err);
                _this.createNotification('error', 'Submitted for Approval', 'An error has occurred');
            });
        }
    };
    RequestFormComponent.prototype.updateAccount = function () {
        var _this = this;
        this.account.UpdateDate = this.today;
        this.account.System = JSON.stringify(this.systemOptions);
        this._requestService.updateAccount(this.accountId, this.account).subscribe(function (res) {
            _this.updateAccountHistory(_this.account);
            _this.createNotification('success', 'Request Updated', 'Request successfully updated');
            _this.showFormInfo(_this.accountId);
        }, function (err) {
            console.log('update request error: ', err);
            _this.createNotification('error', 'Request Updated', 'An error has occurred');
        });
    };
    RequestFormComponent.prototype.approveAccount = function () {
        var _this = this;
        this.getCheckedSystem();
        var systemType = this.checked;
        this.account.SubmissionStatus = Constants.submissionStatuses.approved;
        this.account.ApprovedBy = this.user.firstname + ' ' + this.user.lastname;
        this.account.ApprovalDate = this.today;
        this.account.UpdateDate = this.today;
        this._requestService.updateAccount(this.accountId, this.account).subscribe(function (res) {
            // Notify applicant PI approved
            _this._emailService.createNotificationApproved(_this.accountId, _this.account, systemType).subscribe(function (data) {
            });
            _this.createNotification('success', 'Request Approved', 'Request for Approval status has been updated');
            _this.updateAccountHistory(_this.account);
            _this.showFormInfo(_this.accountId);
            _this.showApprovedMessage = true;
        }, function (err) {
            console.log('update account error: ', err);
            _this.createNotification('error', 'Request Approved', 'An error has occurred');
        });
    };
    RequestFormComponent.prototype.toggleDenyModal = function () {
        this.denyModalVisible = !this.denyModalVisible;
    };
    RequestFormComponent.prototype.denyAccount = function () {
        var _this = this;
        this.getCheckedSystem();
        var systemType = this.checked;
        this.account.SubmissionStatus = Constants.submissionStatuses.denied;
        this.account.DenialReason = this.denialReason;
        this.account.DeniedBy = this.user.firstname + ' ' + this.user.lastname;
        this.account.DenialDate = this.today;
        this.account.UpdateDate = this.today;
        this._requestService.updateAccount(this.accountId, this.account).subscribe(function (res) {
            // Notify applicant PI denied
            _this._emailService.createNotificationDenied(_this.accountId, _this.account, systemType).subscribe(function (data) {
            });
            _this.updateAccountHistory(_this.account);
            _this.showFormInfo(_this.accountId);
            _this.toggleDenyModal();
            _this.createNotification('success', 'Request Denied', 'Request status has been updated');
        }, function (err) {
            console.log('update account error: ', err);
            _this.createNotification('error', 'Request Denied', 'An error has occurred');
        });
    };
    RequestFormComponent.prototype.updateAccountHistory = function (account) {
        account.AccountId = account.Id;
        this._requestService.updateAccountHistory(account).subscribe(function (data) {
        }, function (err) {
            console.log('save account history error: ', err);
        });
    };
    return RequestFormComponent;
}());
export { RequestFormComponent };
