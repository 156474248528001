import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Constants } from '../../constants/constants';
import { LoaderService } from './loader.service';
var SystemTypeService = /** @class */ (function () {
    function SystemTypeService(_http, _loaderService) {
        this._http = _http;
        this._loaderService = _loaderService;
        this.accountRequestApiUrl = Constants.accountRequestApiUrl + "/systemtypes";
        this.headers = new Headers({
            'Content-Type': 'application/json',
            'API_KEY': Constants.apiKey
        });
    }
    SystemTypeService.prototype.updateLoader = function (loading) {
        var _this = this;
        setTimeout(function () {
            _this._loaderService.updateLoadingStatus(loading);
        }, 0);
    };
    SystemTypeService.prototype.createSystemType = function (systemType) {
        var _this = this;
        this.updateLoader(true);
        return this._http.post(this.accountRequestApiUrl, JSON.stringify(systemType), { headers: this.headers })
            .map(function (response) {
            _this.updateLoader(false);
            return response.json();
        })
            .catch(function (error) {
            _this.updateLoader(false);
            return Observable.throw(error);
        });
    };
    SystemTypeService.prototype.getSystemTypes = function () {
        var _this = this;
        this.updateLoader(true);
        return this._http.get(this.accountRequestApiUrl, { headers: this.headers })
            .map(function (response) {
            _this.updateLoader(false);
            return response.json();
        })
            .catch(function (error) {
            _this.updateLoader(false);
            return Observable.throw(error);
        });
    };
    SystemTypeService.prototype.getSystemTypeById = function (systemTypeId) {
        var _this = this;
        this.updateLoader(true);
        return this._http.get(this.accountRequestApiUrl + "/" + systemTypeId, { headers: this.headers })
            .map(function (response) {
            _this.updateLoader(false);
            return response.json();
        })
            .catch(function (error) {
            _this.updateLoader(false);
            return Observable.throw(error);
        });
    };
    SystemTypeService.prototype.updateSystemType = function (systemTypeId, systemType) {
        var _this = this;
        this.updateLoader(true);
        return this._http.put(this.accountRequestApiUrl + "/" + systemTypeId, JSON.stringify(systemType), { headers: this.headers })
            .map(function (response) {
            _this.updateLoader(false);
            return response.json();
        })
            .catch(function (error) {
            _this.updateLoader(false);
            return Observable.throw(error);
        });
    };
    SystemTypeService.prototype.deleteSystemType = function (systemTypeId) {
        var _this = this;
        this.updateLoader(true);
        return this._http.delete(this.accountRequestApiUrl + "/" + systemTypeId, { headers: this.headers })
            .map(function (response) {
            _this.updateLoader(false);
            return response.json();
        })
            .catch(function (error) {
            _this.updateLoader(false);
            return Observable.throw(error);
        });
    };
    return SystemTypeService;
}());
export { SystemTypeService };
