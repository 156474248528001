<div class="settings-container">
  <div *ngIf="mobileDevice === false">
    <div class="ui middle aligned centered grid">
      <div class="column">
        <h1 class="ui left aligned header">System Settings</h1>
        <br>
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="ui small single line table selectable">
          <thead>
            <tr>
              <th class="fifteen wide">System</th>
              <th class="one wide center aligned">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let system of systems">
              <td>
                <a title="Edit system" (click)="toggleSystemTypeModal()" (click)="showSystemTypeInfo(system)">{{ system.Name }}</a>
              </td>
              <td class="center aligned" (click)="toggleDeleteModal()" (click)="getSelectedSystemType(system)">
                <a title="Delete">
                  <i class="trash icon"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <br>
        <button (click)="toggleSystemTypeModal()" (click)="addSystem()" class="ui primary button small">Add System</button>
      </div>
    </div>    
  </div>

</div>
  
<div *ngIf="mobileDevice">
  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="ui small single line table selectable">
    <thead>
      <tr>
        <th class="two wide">System</th>
        <th class="two wide left aligned">Delete</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let system of systems">
        <td>
          <a (click)="toggleSystemTypeModal()" (click)="showSystemTypeInfo(system)">{{ system.Name }}</a>
        </td>
        <td class="center aligned" (click)="toggleDeleteModal()" (click)="getSelectedSystemType(system)">
          <a title="Delete">
            <i class="trash icon"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
  <br>
  <button (click)="toggleSystemTypeModal()" class="ui button small">Add System</button>
</div>

<app-delete-modal (closeDeleteModal)="toggleDeleteModal($event)" *ngIf="deleteModalVisible"></app-delete-modal>

<!-- system type modal -->
<sui-modal *ngIf="systemTypeModalVisible" size="tiny" (dismissed)="toggleSystemTypeModal()" #accountTypeModal>
  <div class="header">System Type</div>
  <div class="content">
    <div class="ui form">
      <div class="field">
        <label>System Name</label>
        <input name="Name" 
          [(ngModel)]="system.Name" 
          [ngModelOptions]="{ standalone: true }" 
          type="text">
      </div>
      <div class="field">
        <label>AD Group</label>
        <input name="Group" 
          [(ngModel)]="system.Group" 
          [ngModelOptions]="{ standalone: true }" 
          type="text">
      </div>
    </div>
  </div>
  <div class="actions">
    <button class="ui small button" (click)="toggleSystemTypeModal()">Cancel</button>
    <button *ngIf="!singleSystem" class="ui small primary button" (click)="submitSystemType()">Submit</button>
    <button *ngIf="singleSystem" class="ui small primary button" (click)="updateSystemType()">Update</button>
  </div>
</sui-modal>
