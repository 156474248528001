<div class="sumbissions-container">
  <div *ngIf="mobileDevice === false">
    <div class="ui middle aligned centered grid">
      <div class="column">
        <h1 class="ui left aligned header">Requests</h1>
        <br>
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="ui small single line table selectable">
          <thead>
            <tr>
              <th class="two wide">Request</th>
              <th class="four wide">Applicant</th>
              <th class="four wide">Email</th>
              <th class="four wide">Username</th>
              <th class="three wide">Company</th>
              <th class="one wide">Submission Date</th>
              <th class="two wide">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let account of accounts">
              <td>
                <a title="View request" [routerLink]="['/request/', account.Id]">Request - {{ account.Id }}</a>
              </td>
              <td>{{ account.FirstName }} {{ account.LastName }}</td>
              <td>{{ account.Email }}</td>
              <td>{{ account.Username }}</td>
              <td>{{ account.Company }}</td>
              <td>{{ account.SubmissionDate }}</td>
              <td>{{ account.SubmissionStatus }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>   
  </div>
</div>

<div *ngIf="mobileDevice">
  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="ui small single line table selectable">
    <thead>
      <tr>
        <th class="two wide">Request</th>
        <th class="two wide">Applicant</th>
        <th class="two wide">Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let account of accounts">
        <td class="link">
          <a [routerLink]="['/request/', account.Id]">Request - {{ account.Id }}</a>
        </td>
        <td>{{ account.FirstName }} {{ account.LastName }}</td>
        <td>{{ account.SubmissionStatus }}</td>
      </tr>
    </tbody>
  </table>
</div>

<app-delete-modal (closeDeleteModal)="toggleDeleteModal($event)" *ngIf="deleteModalVisible"></app-delete-modal>
