import * as $ from 'jquery';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { DataTablesModule } from 'angular-datatables';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { SuiModule } from 'ng2-semantic-ui';

import { APP_COMPONENTS } from './app.components';
import { APP_SERVICES } from './app.services';
import { APP_ROUTES } from './app.routes';

import { AppComponent } from './components/app/app.component';

@NgModule({
    declarations: [
        ...APP_COMPONENTS
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        DataTablesModule,
        HttpModule,
        FormsModule,
        ReactiveFormsModule,
        SimpleNotificationsModule.forRoot(),
        SuiModule,
        RouterModule.forRoot(APP_ROUTES)
    ],
    providers: [
        ...APP_SERVICES
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
