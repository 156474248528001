import { Http, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { LoaderService } from './loader.service';
import { Constants } from '../../constants/constants';
var LoginService = /** @class */ (function () {
    function LoginService(_http, _router, _loaderService) {
        this._http = _http;
        this._router = _router;
        this._loaderService = _loaderService;
        this.loggedInUser = new Subject();
        this.loggedInUserUpdate = this.loggedInUser.asObservable();
        this.apiUrl = Constants.loginAPIUrl;
        this.headers = new Headers({
            'Content-Type': 'application/json'
        });
        this.newRoles = [];
    }
    LoginService.prototype.login = function (username, password) {
        var _this = this;
        this.updateLoader(true);
        var body = {
            username: username,
            password: password
        };
        return this._http.post(this.apiUrl, JSON.stringify(body), { headers: this.headers })
            .map(function (response) {
            _this.user = response.json();
            _this.setUserSessionStorage(_this.user);
            _this.updateLoggedInStatus(_this.user);
            _this.updateLoader(false);
            return _this.user;
        })
            .catch(function (error) {
            _this.updateLoader(false);
            return Observable.throw(error);
        });
    };
    LoginService.prototype.updateLoader = function (loading) {
        this._loaderService.updateLoadingStatus(loading);
    };
    LoginService.prototype.isLoggedIn = function () {
        var user = this.getUserSessionStorage();
        if (user) {
            return true;
        }
        else {
            return false;
        }
    };
    LoginService.prototype.getUserSessionStorage = function () {
        this.updateLoader(true);
        var user = localStorage.getItem('user');
        if (user) {
            this.user = JSON.parse(user);
            this.updateLoggedInStatus(this.user);
            this.updateLoader(false);
            return this.user;
        }
        else {
            this.updateLoader(false);
            return null;
        }
    };
    LoginService.prototype.setUserSessionStorage = function (user) {
        if (!user.error) {
            var firstname = JSON.parse(JSON.stringify(user.firstname)), email = JSON.parse(JSON.stringify(user.email));
            // String to array then for loop to get role
            var roles = this.role = user.groups;
            // Split string
            roles = roles ? roles.split(',') : [];
            for (var _i = 0, roles_1 = roles; _i < roles_1.length; _i++) {
                var i = roles_1[_i];
                // tslint:disable-next-line:no-unused-expression
                i && this.newRoles.push(i);
                roles = this.newRoles;
            }
            // if roles
            var userRole = void 0;
            if (roles.length > 0) {
                for (var _a = 0, roles_2 = roles; _a < roles_2.length; _a++) {
                    var role = roles_2[_a];
                    switch (role) {
                        case 'AM-Admins':
                            userRole = 'admin';
                            localStorage.setItem('userrole', userRole);
                            break;
                        default:
                            userRole = 'general';
                            localStorage.setItem('userrole', userRole);
                            break;
                    }
                    if (userRole === 'admin') {
                        break;
                    }
                }
            }
            else {
                // If no groups assign General
                userRole = 'general';
                localStorage.setItem('userrole', userRole);
            }
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('firstname', firstname);
            localStorage.setItem('email', email);
        }
    };
    LoginService.prototype.getUser = function () {
        return this.user;
    };
    LoginService.prototype.getUserEmail = function () {
        return localStorage.getItem('email');
    };
    LoginService.prototype.getUserName = function () {
        return localStorage.getItem('firstname');
    };
    LoginService.prototype.getUserRole = function () {
        return localStorage.getItem('userrole');
    };
    LoginService.prototype.updateLoggedInStatus = function (user) {
        var loggedIn;
        if (user === undefined) {
            loggedIn = false;
            this.loggedInUser.next(loggedIn);
        }
        if (user !== undefined) {
            loggedIn = this.isLoggedIn ? true : false;
            this.loggedInUser.next(loggedIn);
        }
    };
    LoginService.prototype.logout = function () {
        localStorage.removeItem('user');
        localStorage.removeItem('firstname');
        localStorage.removeItem('email');
        localStorage.removeItem('adminemail');
        localStorage.removeItem('userrole');
        this.user = undefined;
        this.loggedInUser.next(false);
        this._router.navigateByUrl('login');
    };
    return LoginService;
}());
export { LoginService };
