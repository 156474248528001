import { Component, OnInit, DoCheck, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { RequestService } from '../../services/request.service';
import { LoaderService } from '../../services/loader.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  private routerSubscription;
  public mobileDevice: boolean;
  public url: string;

  public generalUser = this._loginService.getUserRole() === 'general';
  public piUser = this._loginService.getUserRole() === 'pi';
  public adminUser = this._loginService.getUserRole() === 'admin';

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _loaderService: LoaderService,
    private _loginService: LoginService,
    private _requestService: RequestService,
    private _router: Router
  ) {

    this.routerSubscription = this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;

        if (this.url === '/user' && this.adminUser) {
          this._router.navigateByUrl('user/all-submissions');
        }
        if (this.url === '/user' && this.piUser) {
          this._router.navigateByUrl('user/submissions');
        }
        if (this.url === '/user' && this.generalUser) {
          this._router.navigateByUrl('user/user-account');
        }

      }
    });

  }

  ngOnInit() {
    this.mobileDevice = this._loaderService.getMobileStatus();
    // Detect changes of userRole
    this._changeDetectorRef.detectChanges();
  }

}
