import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import { LoaderService } from './loader.service';
import { Constants } from '../../constants/constants';
var DirectorySearchService = /** @class */ (function () {
    function DirectorySearchService(_http, _loaderService) {
        this._http = _http;
        this._loaderService = _loaderService;
        this.graphUrl = Constants.graphUrl;
    }
    DirectorySearchService.prototype.updateLoadingStatus = function (loading) {
        this._loaderService.updateDirectorySearchLoadingStatus(loading);
    };
    DirectorySearchService.prototype.getAccessToken = function () {
        return this._http.get(Constants.authUrl)
            .map(function (response) { return response.json().token; })
            .catch(function (error) { return Observable.throw(error.json().error || 'Server error'); });
    };
    DirectorySearchService.prototype.searchUsers = function (searchVal) {
        var _this = this;
        return this.getAccessToken()
            .switchMap(function (accessToken) {
            var headers = new Headers({
                'Authorization': "Bearer " + accessToken
            });
            var searchUrl = _this.graphUrl + "?$filter=startswith(givenName,'" + searchVal + "') or startswith(surName,'" + searchVal + "') or startswith(displayName,'" + searchVal + "') or startswith(mail,'" + searchVal + "')";
            return _this._http.get(searchUrl, { headers: headers })
                .map(function (response) { return response.json().value; })
                .catch(function (error) { return Observable.throw(error.json().error || 'Server error'); });
        });
    };
    return DirectorySearchService;
}());
export { DirectorySearchService };
