import { Component, OnInit, Input, ViewChild, ViewChildren, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as moment from 'moment';

import { DirectorySearchService } from '../../services/directory-search.service';
import { EmailService } from '../../services/email.service';
import { LoaderService } from '../../services/loader.service';
import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../services/notification.service';
import { RequestService } from '../../services/request.service';
import { SystemTypeService } from '../../services/system-type.service';

import { Constants } from '../../../constants/constants';

import { DirectoryResult } from '../../../models/directoryResult';
import { Email } from '../../../models/email';
import { Account } from '../../../models/account';
import { User } from '../../../models/user';
import { toArray } from 'rxjs/operator/toArray';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.css']
})
export class UserAccountComponent implements OnInit {
  @Input() public url: string;
  @ViewChildren('checkbox') checkbox;

  public account: any = {};
  // public projectOptions = [];
  public systems: any = {};
  public system: any = {};
  public systemOptions = [];
  private user: User;

  public searchResults: Array<DirectoryResult>;
  public accountActivated = false;
  public accountExists = false;
  private adminSubscription;
  private approved = false;
  private checked;
  private checkedSystems;
  private email;
  public isLoading;
  private password;
  private passwordConfirmation;
  public changePasswordModalVisible: boolean;
  public formErrors: Array<string> = [];
  private selectedField;
  public showAdminButtons = false;
  public showDenialReason = false;
  public selectedCheckboxes = [];
  private today = moment().format('MM/DD/YYYY');
  public updatePassword = false;
  public clubhouseUser = true;
  private userEmail;
  private userRole;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _directorySearchService: DirectorySearchService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _emailService: EmailService,
    private _loaderService: LoaderService,
    private _loginService: LoginService,
    private _notificationService: NotificationService,
    private _requestService: RequestService,
    private _router: Router,
    private _systemTypeService: SystemTypeService
  ) { }

  ngOnInit() {
    this.user = this._loginService.getUser();
    this.userEmail = this._loginService.getUserEmail();
    this.userRole = this._loginService.getUserRole();

    this._systemTypeService.getSystemTypes().subscribe((data) => {
      this.systemOptions = data;
    });

    this.showFormInfo();

  }

  private toggleLoader(loading: boolean) {
    this._loaderService.updateLoadingStatus(loading);
  }

  public toggleChangePasswordModal() {
    this.changePasswordModalVisible = !this.changePasswordModalVisible;
  }

  private showFormInfo() {
    this._requestService.getAccountByUserEmail(this.userEmail).subscribe(
      res => {
        // If account not activated yet display
        if (res.length > 0) {
          this.account = res[0];
          // If account created and temporary password not been updated yet
          if (this.account.SubmissionStatus === Constants.submissionStatuses.created ) {
            this.updatePassword = true;
          }

          // System checkboxes
          let newJson = this.account.System.replace(/([a-zA-Z0-9]+?):/g, '"$1":');

          newJson = newJson.replace(/'/g, '"');
          this.systemOptions = JSON.parse(newJson);

        } else {
          this._directorySearchService.searchUsers(this.user.firstname + ' ' + this.user.lastname).subscribe(
            response => {
              const accounts = response;

              if (Array.isArray(accounts) && accounts.length > 0) {
                this.searchResults = accounts.filter((result: DirectoryResult) => {
                  this.account.FirstName = this.user.firstname;
                  this.account.LastName = this.user.lastname;
                  this.account.Email = result.mail;

                  return result;

                });
              } else {
                // If no account
                this.account = {};
              }

            },
            error => {
              console.log('search by name error: ', error);
              // If no account
              this.account = {};
            }
          );

        }
      },
      error => {
        console.log('get account by id error: ', error);
      }
    );

  }

  private createNotification(notificationType: string, notificationTitle: string, notificationMessage: string) {
    switch (notificationType) {
      case 'success':
        this._notificationService.createSuccessNotification(notificationTitle, notificationMessage);
        break;
      case 'error':
        this._notificationService.createErrorNotification(notificationTitle, notificationMessage);
        break;
      default:
        break;
    }
  }

  private validateForm(account: Account) {
    this.formErrors = [];

    if (!this.password) {
      this.formErrors.push('password');
      return false;
    }
    if (!this.password.match(/^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{10,32}$/)) {
      this.formErrors.push('passwordRules');
      return false;
    }
    if (this.passwordConfirmation !== this.password) {
      this.formErrors.push('passwordConfirmation');
      return false;
    }

    if (!this.formErrors.length) {
      return true;
    }
  }

  private getCheckedSystem() {
    const systems = JSON.parse(this.account.System);
    console.log(systems);


    systems.forEach(system => {
      console.log(systems);
      if (system.Name === 'MMPVAE' && system.checked === true) {
        this.checked = 'mmpvae';
      }
      if (system.Name === 'Clubhouse Support Portal' && system.checked === true) {
        this.checked = 'clubhouse';
      }

    });

  }

  public updateAccount() {
    const valid = this.validateForm(this.account);

    if (valid) {
      this.getCheckedSystem();
      const systemType = this.checked;

      this.account.SubmissionStatus = Constants.submissionStatuses.updated;
      this.account.Password = this.password;
      this.account.System = JSON.stringify(this.systemOptions);
      this.account.UpdateDate = this.today;

      this._requestService.updateAccount(this.account.Id, this.account).subscribe(
        res => {
          this.updateAccountHistory(this.account);
          // Notify MMPVAE admin of request
          if (systemType === 'mmpvae') {
            this._emailService.createAdminNotificationAccountActivated(this.account.Id, this.account, 'mmpvae').subscribe((data) => {

            });
          }
          this.createNotification('success', 'Password Updated', 'Your temporary password was successfully updated. You will receive a confirmation email when your account has been activated.');
          this.updatePassword = false; // hide section
          this.accountActivated = true; // show section
          this.showFormInfo();

        },
        err => {
          console.log('update request error: ', err);
          this.createNotification('error', 'Password Updated', 'An error has occurred');
        }
      );

    }

  }

  private changePassword() {
    const valid = this.validateForm(this.account);

    if (valid) {
      // Create account with reset status
      this.account.FirstName = this.account.FirstName;
      this.account.LastName = this.account.LastName;
      this.account.Email = this.account.Email;
      this.account.Username = this.user.username;
      this.account.Password = this.password;
      this.account.UpdateDate = this.today;
      this.account.SubmissionStatus = Constants.submissionStatuses.reset;

      this._requestService.createAccount(this.account).subscribe(
        res => {
          this.updateAccountHistory(res);
          this.toggleChangePasswordModal();
          this.createNotification('success', 'Change Password Request', 'Your request to change your password has been submitted. You will receive a confirmation email when the password has been updated.');
        },
        err => {
          console.log('update request error: ', err);
          this.createNotification('error', 'Change Password Request', 'An error has occurred');
        }
      );
    }

  }

  private updateAccountHistory(account) {
    this.account.AccountId = this.account.Id;

    this._requestService.updateAccountHistory(this.account).subscribe((data) => {

    },
      err => {
        console.log('save account history error: ', err);
      });
  }

}
