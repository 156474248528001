import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormsModule, FormGroup, FormBuilder } from '@angular/forms';
// tslint:disable-next-line:import-blacklist
import { Subject } from 'rxjs/Rx';
import 'rxjs/add/operator/map';

import { DataTableDirective } from 'angular-datatables';

import { LoaderService } from '../../services/loader.service';
import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../services/notification.service';
import { SystemTypeService } from '../../services/system-type.service';

import { Constants } from '../../../constants/constants';

import { Account } from '../../../models/account';
import { DirectoryResult } from '../../../models/directoryResult';
import { Email } from '../../../models/email';
import { SystemType } from '../../../models/systemType';
import { User } from '../../../models/user';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, AfterViewInit {
  @Input() public url: string;
  @Input() public systemTypeModalVisible: boolean;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  public account: Account;
  public systems: any = {};
  private systemType: any = {};
  private system: any = {};
  public user: User;

  private adminSubscription;
  public adminUser = false;
  public deleteModalVisible: boolean;
  public formErrors: Array<string> = [];
  private loaderSubscription;
  private loadingInProgress: boolean;
  public mobileDevice: boolean;
  private notificationOptions = Constants.notificationOptions;
  private singleSystem = false;
  public typeName;
  public typeId;
  private userEmail;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _loaderService: LoaderService,
    private _loginService: LoginService,
    private _notificationService: NotificationService,
    private _router: Router,
    private _systemTypeService: SystemTypeService
  ) { }

  ngOnInit() {
    this.mobileDevice = this._loaderService.getMobileStatus();

    this.loaderSubscription = this._loaderService.apiCallUpdate.subscribe(waiting => {
      this.loadingInProgress = waiting;

      if (this.loadingInProgress === false) {
        this.dtTrigger.next();
      }
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order: [1, 'desc'],
    };

    // Admin
    // this.adminSubscription = this._loginService.adminUserUpdate.subscribe(userAdmin => {
    //   this.adminUser = userAdmin;
    // });

    // this._loginService.updateAdminStatus(this._loginService.getUser());
    this.user = this._loginService.getUser();
    this.userEmail = this._loginService.getUserEmail();
    this.systems = Constants.systemTypes;

  }

  ngAfterViewInit(): void {
    this._systemTypeService.getSystemTypes().subscribe((data) => {
      this.dtTrigger.next();
      this.systems = data;
    });
  }

  private toggleLoader(loading: boolean) {
    this._loaderService.updateLoadingStatus(loading);
  }

  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      this._systemTypeService.getSystemTypes().subscribe((data) => {
        // Call the dtTrigger to rerender
        this.dtTrigger.next();
        this.systems = data;
      });
    });
  }

  private createNotification(notificationType: string, notificationTitle: string, notificationMessage: string) {
    switch (notificationType) {
      case 'success':
        this._notificationService.createSuccessNotification(notificationTitle, notificationMessage);
        break;
      case 'error':
        this._notificationService.createErrorNotification(notificationTitle, notificationMessage);
        break;
      default:
        break;
    }
  }

  private toggleSystemTypeModal() {
    this.systemTypeModalVisible = !this.systemTypeModalVisible;
  }

  private addSystem() {
    this.singleSystem = false;
  }

  private submitSystemType() {
    this._systemTypeService.createSystemType(this.system).subscribe(
      response => {
        // tslint:disable-next-line:max-line-length
        this.createNotification('success', 'Submit Sytem', 'System Type successfully saved');
        this.rerender();
        this.toggleSystemTypeModal();
      },
      err => {
        console.log('submit submission error: ', err);
        this.createNotification('error', 'Submit Sytem', 'An error has occurred');
      }
    );

  }

  public getSelectedSystemType(system) {
    this.system = system;
  }

  private showSystemTypeInfo(system) {
    this._systemTypeService.getSystemTypeById(system.Id).subscribe(
      res => {
        this.system = res;
        this.singleSystem = true;
      },
      error => {
        console.log('get submission by id error: ', error);
      }
    );
  }

  private updateSystemType() {
    this._systemTypeService.updateSystemType(this.system.Id, this.system).subscribe(
      response => {
        this.createNotification('success', 'Update Submission', 'Submission successfully saved');
        this.rerender();
        this.toggleSystemTypeModal();
      },
      err => {
        console.log('update conf grant submission error: ', err);
        this.createNotification('error', 'Update Submission', 'An error has occurred');
      }
    );
  }

  public toggleDeleteModal(selectedSystem?) {
    this.deleteModalVisible = !this.deleteModalVisible;
    if (selectedSystem === 'delete') {
      this.deleteSystem(this.system);
    }
  }

  public deleteSystem(system) {
    if (system.Id) {
      this._systemTypeService.deleteSystemType(system.Id).subscribe(
        response => {
          this.createNotification('success', 'Delete Submission', 'Submission has been deleted');
          this.rerender();
        },
        err => {
          console.log('update conf grant err: ', err);
          this.createNotification('error', 'Delete Submission', 'An error has occurred. Please try again');
        }
      );
    }

  }

}
