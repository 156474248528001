import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Constants } from '../../constants/constants';
import { LoaderService } from './loader.service';
var RequestService = /** @class */ (function () {
    function RequestService(_http, _loaderService) {
        this._http = _http;
        this._loaderService = _loaderService;
        this.accountRequestApiUrl = Constants.accountRequestApiUrl + "/accounts";
        this.accountHistoryRequestApiUrl = Constants.accountRequestApiUrl + "/accounthistories";
        // private projectApiUrl = `${Constants.projectApiUrl}`;
        this.headers = new Headers({
            'Content-Type': 'application/json',
            'API_KEY': Constants.apiKey
        });
    }
    RequestService.prototype.updateLoader = function (loading) {
        var _this = this;
        setTimeout(function () {
            _this._loaderService.updateLoadingStatus(loading);
        }, 0);
    };
    RequestService.prototype.createAccount = function (account) {
        var _this = this;
        this.updateLoader(true);
        return this._http.post(this.accountRequestApiUrl, JSON.stringify(account), { headers: this.headers })
            .map(function (response) {
            _this.updateLoader(false);
            return response.json();
        })
            .catch(function (error) {
            _this.updateLoader(false);
            return Observable.throw(error);
        });
    };
    RequestService.prototype.getAccounts = function () {
        var _this = this;
        this.updateLoader(true);
        return this._http.get(this.accountRequestApiUrl, { headers: this.headers })
            .map(function (response) {
            _this.updateLoader(false);
            return response.json();
        })
            .catch(function (error) {
            _this.updateLoader(false);
            return Observable.throw(error);
        });
    };
    RequestService.prototype.getAccountByUserEmail = function (userEmail) {
        var _this = this;
        this.updateLoader(true);
        return this._http.get(this.accountRequestApiUrl + "?userEmail=" + userEmail, { headers: this.headers })
            .map(function (response) {
            _this.updateLoader(false);
            return response.json();
        })
            .catch(function (error) {
            _this.updateLoader(false);
            return Observable.throw(error);
        });
    };
    RequestService.prototype.getAccountByToken = function (token) {
        var _this = this;
        this.updateLoader(true);
        return this._http.get(this.accountRequestApiUrl + "?token=" + token, { headers: this.headers })
            .map(function (response) {
            _this.updateLoader(false);
            return response.json();
        })
            .catch(function (error) {
            _this.updateLoader(false);
            return Observable.throw(error);
        });
    };
    RequestService.prototype.getAccountsByPIEmail = function (piEmail) {
        var _this = this;
        this.updateLoader(true);
        return this._http.get(this.accountRequestApiUrl + "?piEmail=" + piEmail, { headers: this.headers })
            .map(function (response) {
            _this.updateLoader(false);
            return response.json();
        })
            .catch(function (error) {
            _this.updateLoader(false);
            return Observable.throw(error);
        });
    };
    RequestService.prototype.getAccountById = function (accountId) {
        var _this = this;
        this.updateLoader(true);
        return this._http.get(this.accountRequestApiUrl + "/" + accountId, { headers: this.headers })
            .map(function (response) {
            _this.updateLoader(false);
            return response.json();
        })
            .catch(function (error) {
            _this.updateLoader(false);
            return Observable.throw(error);
        });
    };
    RequestService.prototype.updateAccount = function (accountId, account) {
        var _this = this;
        this.updateLoader(true);
        return this._http.put(this.accountRequestApiUrl + "/" + accountId, JSON.stringify(account), { headers: this.headers })
            .map(function (response) {
            _this.updateLoader(false);
            return response.json();
        })
            .catch(function (error) {
            _this.updateLoader(false);
            return Observable.throw(error);
        });
    };
    RequestService.prototype.deleteAccount = function (accountId) {
        var _this = this;
        this.updateLoader(true);
        return this._http.delete(this.accountRequestApiUrl + "/" + accountId, { headers: this.headers })
            .map(function (response) {
            _this.updateLoader(false);
            return response.json();
        })
            .catch(function (error) {
            _this.updateLoader(false);
            return Observable.throw(error);
        });
    };
    RequestService.prototype.updateAccountHistory = function (account) {
        return this._http.post("" + this.accountHistoryRequestApiUrl, JSON.stringify(account), { headers: this.headers })
            .map(function (response) {
            return response.json();
        })
            .catch(function (error) {
            return Observable.throw(error);
        });
    };
    return RequestService;
}());
export { RequestService };
