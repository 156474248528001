<div class="app-container">
    <sui-dimmer class="page inverted" [isDimmed]="isLoading">
        <div class="ui text loader">Loading</div>
    </sui-dimmer>
    <!-- <div *ngIf="currentRoute?.indexOf('login') < 0" class="ui top attached blue inverted menu"> -->
    <div class="ui top attached blue inverted menu">
        <a class="item" (click)="sidebar.toggle()">
            <i class="sidebar icon"></i>
        </a>
        <div class="right menu">            
            <a *ngIf="!loggedIn" (click)="backToRequestForm()" class="item">
                <div>
                    <i class="home icon"></i>
                    Home
                </div>
            </a>
            <a *ngIf="loggedIn" (click)="backToDashboard()" class="item">
                <div>
                    <i class="home icon"></i>
                    Home
                </div>
            </a>
            <a [routerLink]="['/help']" class="item">
                <div>Help</div>
            </a>
            <!-- <div class="ui dropdown floating pointing top right link item right-padding" suiDropdown>
                <span class="text">Help</span>
                <i class="dropdown icon"></i>
                <div class="menu" suiDropdownMenu>
                    <a class="dropdown-link" href="https://help.rs.gsu.edu/display/PD/Account+Request" target="blank" class="item">How-to Guides</a>
                </div>
            </div> -->
            <div *ngIf="!loggedIn" class="ui dropdown floating pointing top right link item right-padding" suiDropdown>
                <span class="text">Hello, Guest</span>
                <i class="dropdown icon"></i>
                <div class="menu" suiDropdownMenu>
                    <a class="dropdown-link" href="https://projects.rs.gsu.edu/servicedesk/customer/portal/13" target="blank" class="item">Report Issue/Suggest New Feature</a>
                    <div class="divider"></div>
                    <a class="dropdown-link" [routerLink]="['/login']"class="item">Login</a>
                </div>
            </div>
            <div *ngIf="loggedIn" class="ui dropdown floating pointing top right link item right-padding" suiDropdown>
                <span class="text">Hello, {{ userName }}</span>
                <i class="dropdown icon"></i>
                <div class="menu" suiDropdownMenu>
                    <a class="dropdown-link" href="https://projects.rs.gsu.edu/servicedesk/customer/portal/13" target="blank" class="item">Report Issue/Suggest New Feature</a>
                    <div class="divider"></div>
                    <a class="dropdown-link" (click)="logout()" class="item">Logout</a>
                </div>
            </div>
        </div>
    </div>
    <sui-sidebar-container class="ui bottom attached segment">
        <sui-sidebar class="inverted blue vertical" #sidebar>
            <a href="https://researchsolutions.atlassian.net/servicedesk/customer/portal/12" target="blank" class="item">Report an Issue/Suggest New Feature</a>
            <a href="https://researchportal.gsu.edu/Home/MyProjects" target="blank" class="item">My Projects</a>
            <a class="item ui primary dropdown button" suiDropdown>
                Reports
                <i class="dropdown icon"></i>
                <div class="ui blue menu" suiDropdownMenu>
                    <a href="https://researchportal.gsu.edu/Home/MyGrants" target="blank" class="item">My Grants</a>
                    <a href="https://researchportal.gsu.edu/Home/UniversityTotal" target="blank" class="item">University Total</a>
                    <a href="https://researchportal.gsu.edu/Home/Colleges" target="blank" class="item">College Totals</a>
                    <a href="https://researchportal.gsu.edu/Home/Departments" target="blank" class="item">Department Totals</a>
                    <a href="https://researchportal.gsu.edu/Home/FacultyMember" target="blank" class="item">Faculty Member Totals</a>
                    <a href="https://researchportal.gsu.edu/Home/Sponsor" target="blank" class="item">Sponsor</a>
                </div>
            </a>
            <a href="https://idp.gsu.edu/oxauth/login" target="blank" class="item">Cayuse424</a>
            <a class="item ui primary dropdown button" suiDropdown>
                OSPA
                <i class="dropdown icon"></i>
                <div class="ui blue menu" suiDropdownMenu>
                    <a href="https://researchportal.gsu.edu/Home/OSPA" target="blank" class="item inverted-text">My Submissions</a>
                    <a href="https://researchportal.gsu.edu/Home/CollegeQueue" target="blank" class="inverted-text item">College Queue</a>
                    <a href="https://researchportal.gsu.edu/Home/OSPAQueue" target="blank" class="inverted-text item">OSPA Queue</a>
                </div>
            </a>
            <a href="https://internalgrants.gsu.edu/" target="blank" class="item">Internal Grants</a>
            <a href="https://dar.gsu.edu/" target="blank" class="item">DAR</a>
            <a href="https://mmpvae.gsu.edu/" target="blank" class="item">MMPVAE</a>
        </sui-sidebar>
        <sui-sidebar-sibling [isDimmedWhenVisible]="true">
            <router-outlet></router-outlet>
        </sui-sidebar-sibling>
    </sui-sidebar-container>
</div>

<!-- notification -->
<simple-notifications [options]="notificationOptions"></simple-notifications>
