<sui-modal size="small" (dismissed)="closeModal()" #modal>
  <div class="header">Delete Item</div>
  <div class="content">
    <h4 class="ui header">Are you sure you want to delete this item?</h4>
  </div>
  <div class="actions">
    <button class="ui button" (click)="modal.deny(closeModal('cancel'))">No, Cancel</button>
    <button class="ui red button" (click)="modal.approve(closeModal('delete'))">Yes, Delete</button>
  </div>
</sui-modal>
