import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { LoginService } from '../../services/login.service';
import { LoaderService } from '../../services/loader.service';

import { Constants } from '../../../constants/constants';

import { User } from '../../../models/user';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    private user: User;

    private adminSubscription;
    public currentRoute: string;
    public isLoading: boolean;
    public isLoggedIn: boolean;
    private loaderSubscription;
    public loggedIn = false;
    public loggedInUser;
    private loggedInSubscription;
    public mobileDevice: boolean;
    public notificationOptions = Constants.notificationOptions;
    public userName: any;
    public userRole;

    constructor(
        private _loginService: LoginService,
        private _loaderService: LoaderService,
        private _router: Router
    ) { }

    public ngOnInit() {
        if (window.innerWidth <= 800) {
            this.mobileDevice = true;
        } else {
            this.mobileDevice = false;
        }

        this.userRole = this._loginService.getUserRole();

        this.loggedInSubscription = this._loginService.loggedInUserUpdate.subscribe(
            loggedIn => {
                this.loggedIn = loggedIn;

                if (!this.user) {
                    this.user = this._loginService.getUser();
                    this.userName = this.user ? this.user.firstname : localStorage.getItem('firstname');
                } else {
                    this.userName = this.user.firstname;
                }
            }
        );

        this.loaderSubscription = this._loaderService.loadingUpdate.subscribe(loading => {
            this.isLoading = loading;
        });

    }

    public ngOnDestroy() {
        this.adminSubscription.unsubscribe();
    }

    public backToRequestForm() {
        this._router.navigateByUrl('');
    }

    public backToDashboard() {
        this._router.navigateByUrl('user/user-account');
    }

    private logout() {
        this.user = undefined;
        this.userRole = undefined;

        this._loginService.logout();

    }
}
