/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-modal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ng2-semantic-ui/dist/modules/modal/components/modal.ngfactory";
import * as i3 from "ng2-semantic-ui/dist/modules/modal/components/modal";
import * as i4 from "ng2-semantic-ui/dist/misc/util/services/component-factory.service";
import * as i5 from "./delete-modal.component";
var styles_DeleteModalComponent = [i0.styles];
var RenderType_DeleteModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteModalComponent, data: {} });
export { RenderType_DeleteModalComponent as RenderType_DeleteModalComponent };
export function View_DeleteModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "sui-modal", [["size", "small"]], null, [[null, "dismissed"], ["document", "keyup"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onDocumentKeyUp($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onDocumentResize() !== false);
        ad = (pd_1 && ad);
    } if (("dismissed" === en)) {
        var pd_2 = (_co.closeModal() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_SuiModal_0, i2.RenderType_SuiModal)), i1.ɵdid(1, 4308992, [["modal", 4]], 0, i3.SuiModal, [i1.Renderer2, i1.ElementRef, i4.SuiComponentFactory], { size: [0, "size"] }, { onDismiss: "dismissed" }), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete Item"])), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(6, 0, null, 0, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h4", [["class", "ui header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure you want to delete this item?"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵeld(12, 0, null, 0, 7, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "ui button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).deny(_co.closeModal("cancel")) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["No, Cancel"])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "ui red button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).approve(_co.closeModal("delete")) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Yes, Delete"])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = "small"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DeleteModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delete-modal", [], null, null, null, View_DeleteModalComponent_0, RenderType_DeleteModalComponent)), i1.ɵdid(1, 114688, null, 0, i5.DeleteModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeleteModalComponentNgFactory = i1.ɵccf("app-delete-modal", i5.DeleteModalComponent, View_DeleteModalComponent_Host_0, {}, { closeDeleteModal: "closeDeleteModal" }, []);
export { DeleteModalComponentNgFactory as DeleteModalComponentNgFactory };
