import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { Constants } from '../../constants/constants';

@Injectable()
export class TokenService {
  private tokenApiUrl = `${Constants.tokenApiUrl}`;

  private headers = new Headers({
    'Content-Type': 'application/json',
    'API_KEY': Constants.apiKey
  });

  constructor(
    private _http: Http
  ) { }

  public getToken(size) {
    return this._http.get(`${this.tokenApiUrl}`, { headers: this.headers })
      .map(response => {
        return response.json();
      })
      .catch(error => {
        return Observable.throw(error);
      });
  }

}
