import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { RequestService } from '../../services/request.service';
import { LoaderService } from '../../services/loader.service';
import { LoginService } from '../../services/login.service';
var UserComponent = /** @class */ (function () {
    function UserComponent(_changeDetectorRef, _loaderService, _loginService, _requestService, _router) {
        var _this = this;
        this._changeDetectorRef = _changeDetectorRef;
        this._loaderService = _loaderService;
        this._loginService = _loginService;
        this._requestService = _requestService;
        this._router = _router;
        this.generalUser = this._loginService.getUserRole() === 'general';
        this.piUser = this._loginService.getUserRole() === 'pi';
        this.adminUser = this._loginService.getUserRole() === 'admin';
        this.routerSubscription = this._router.events.subscribe(function (event) {
            if (event instanceof NavigationEnd) {
                _this.url = event.url;
                if (_this.url === '/user' && _this.adminUser) {
                    _this._router.navigateByUrl('user/all-submissions');
                }
                if (_this.url === '/user' && _this.piUser) {
                    _this._router.navigateByUrl('user/submissions');
                }
                if (_this.url === '/user' && _this.generalUser) {
                    _this._router.navigateByUrl('user/user-account');
                }
            }
        });
    }
    UserComponent.prototype.ngOnInit = function () {
        this.mobileDevice = this._loaderService.getMobileStatus();
        // Detect changes of userRole
        this._changeDetectorRef.detectChanges();
    };
    return UserComponent;
}());
export { UserComponent };
