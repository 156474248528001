import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { LoaderService } from '../../services/loader.service';
import { Constants } from '../../../constants/constants';
var AppComponent = /** @class */ (function () {
    function AppComponent(_loginService, _loaderService, _router) {
        this._loginService = _loginService;
        this._loaderService = _loaderService;
        this._router = _router;
        this.loggedIn = false;
        this.notificationOptions = Constants.notificationOptions;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (window.innerWidth <= 800) {
            this.mobileDevice = true;
        }
        else {
            this.mobileDevice = false;
        }
        this.userRole = this._loginService.getUserRole();
        this.loggedInSubscription = this._loginService.loggedInUserUpdate.subscribe(function (loggedIn) {
            _this.loggedIn = loggedIn;
            if (!_this.user) {
                _this.user = _this._loginService.getUser();
                _this.userName = _this.user ? _this.user.firstname : localStorage.getItem('firstname');
            }
            else {
                _this.userName = _this.user.firstname;
            }
        });
        this.loaderSubscription = this._loaderService.loadingUpdate.subscribe(function (loading) {
            _this.isLoading = loading;
        });
    };
    AppComponent.prototype.ngOnDestroy = function () {
        this.adminSubscription.unsubscribe();
    };
    AppComponent.prototype.backToRequestForm = function () {
        this._router.navigateByUrl('');
    };
    AppComponent.prototype.backToDashboard = function () {
        this._router.navigateByUrl('user/user-account');
    };
    AppComponent.prototype.logout = function () {
        this.user = undefined;
        this.userRole = undefined;
        this._loginService.logout();
    };
    return AppComponent;
}());
export { AppComponent };
