import { Component, OnInit, Input, ViewChild, ViewChildren, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormsModule, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as moment from 'moment';

import { DirectorySearchService } from '../../services/directory-search.service';
import { EmailService } from '../../services/email.service';
import { LoaderService } from '../../services/loader.service';
import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../services/notification.service';
import { RequestService } from '../../services/request.service';
import { SystemTypeService } from '../../services/system-type.service';

import { Constants } from '../../../constants/constants';

import { DirectoryResult } from '../../../models/directoryResult';
import { Email } from '../../../models/email';
import { Account } from '../../../models/account';
import { User } from '../../../models/user';
import { toArray } from 'rxjs/operator/toArray';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.css']
})
export class RequestFormComponent implements OnInit {
  @Input() public url: string;
  @Input() public denyModalVisible: boolean;
  @Input() sumbission: any;
  @ViewChildren('checkbox') checkbox;

  public account: any = {};
  public directoryResult: DirectoryResult;
  public projectOptions = [];
  public systems: any = {};
  public system: any = {};
  public systemOptions = [];
  public user: User;

  public accountId;
  public adminSubscription;
  public adminUser = false;
  public approved = false;
  public checkboxArray = [];
  public checked;
  public checkedSystems;
  public countries = Constants.countryDropdownOptions;
  public denialReason;
  public directorySearchModalVisible: boolean;
  public emailExists = false;
  public existingEmail = false;
  public existingEmails = [];
  public formErrors: Array<string> = [];
  public generalUser = false;
  public guestUser = false;
  // public noProjects = false;
  public piSubscription;
  public requestedByName;
  public requestSubmitted = false;
  public selectedCheckboxes = [];
  public selectedField;
  public singleRequest = false;
  public showAdminButtons = false;
  public showApprovedMessage = false;
  public showCDERDetails = false;
  public showDenialReason = false;
  public today = moment().format('MM/DD/YYYY');
  public userEmail;
  public userName;
  public userRole;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _directorySearchService: DirectorySearchService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _emailService: EmailService,
    private _loginService: LoginService,
    private _loaderService: LoaderService,
    private _notificationService: NotificationService,
    private _requestService: RequestService,
    private _router: Router,
    private _systemTypeService: SystemTypeService
  ) { }

  ngOnInit() {
    this._activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      this.accountId = paramMap.get('id');
      this.showFormInfo(this.accountId);
    });

    // Set user
    this._loginService.updateLoggedInStatus(this._loginService.getUser());
    this.user = this._loginService.getUser();
    this.userEmail = this._loginService.getUserEmail();
    this.userName = this._loginService.getUserName();
    this.userRole = this._loginService.getUserRole();

    // Admin user
    if (this.userRole === 'admin' || this.userRole === null ) {
      this.adminUser = true;
    }

  }

  private toggleLoader(loading: boolean) {
    this._loaderService.updateLoadingStatus(loading);
  }

  private showSystemCheckboxes(account) {
    this._systemTypeService.getSystemTypes().subscribe((data) => {
      let jsonData = [];

      // show legacy system types in activated accounts
      // else only show CDER and Recovery Support Portal
      if (account === 'noAccount') {
        jsonData = data.filter(system => system.Name !== 'Dropbox' && system.Name !== 'GSU IACUC' && system.Name !== 'GSU IRB' && system.Name !== 'MMPVAE' && system.Name !== 'SFI');

        // alphabetize system names
        const alphaData = jsonData.sort((a, b) => a.Name.localeCompare(b.Name));

        this.systemOptions = alphaData;
      }

    });

  }

  private showFormInfo(accountId) {
    if (accountId !== null) {
      this._requestService.getAccountById(accountId).subscribe(
        res => {
          this.account = res;
          // System checkboxes
          let newJson = this.account.System.replace(/([a-zA-Z0-9]+?):/g, '"$1":');

          newJson = newJson.replace(/'/g, '"');
          const alphaData = JSON.parse(newJson);

          this.systemOptions = alphaData.sort((a, b) => a.Name.localeCompare(b.Name));

          // Display country field if CDER is checked
          this.systemOptions.forEach(system => {
            if (system.Name === 'CDER' && system.checked === true) {
              // Default Rajshekhar Sunderraman (rsunderraman@gsu.edu) as PI
              this.account.PI = 'Rajshekhar Sunderraman';
              this.account.PIEmail = 'rsunderraman@gsu.edu';
              this.showCDERDetails = true;
            }
          });

          // Single request form
          this.singleRequest = true;
          this.showAdminButtons = true;
          // If approved
          if (this.account.SubmissionStatus === Constants.submissionStatuses.approved || this.account.SubmissionStatus === Constants.submissionStatuses.updated || this.account.SubmissionStatus === Constants.submissionStatuses.denied || this.account.SubmissionStatus === Constants.submissionStatuses.created || this.account.SubmissionStatus === Constants.submissionStatuses.activated) {
            this.approved = true;
          }
          // If approved show message
          if (this.account.SubmissionStatus === Constants.submissionStatuses.approved || this.account.SubmissionStatus === Constants.submissionStatuses.updated || this.account.SubmissionStatus === Constants.submissionStatuses.created || this.account.SubmissionStatus === Constants.submissionStatuses.activated) {
            this.showApprovedMessage = true;
          }
          // If denied show reason
          if (this.account.SubmissionStatus === Constants.submissionStatuses.denied && this.account.DenialReason !== null) {
            this.denialReason = this.account.DenialReason;
            this.showDenialReason = true;
          }

        },
        error => {
          console.log('get submission by id error: ', error);
        }
      );
    } else {
      this.showSystemCheckboxes('noAccount');
    }

  }

  private createNotification(notificationType: string, notificationTitle: string, notificationMessage: string) {
    switch (notificationType) {
      case 'success':
        this._notificationService.createSuccessNotification(notificationTitle, notificationMessage);
        break;
      case 'error':
        this._notificationService.createErrorNotification(notificationTitle, notificationMessage);
        break;
      default:
        break;
    }
  }

  private backToDashboard() {
    if (this.userRole === 'admin') {
      this._router.navigateByUrl('user/all-submissions');
    } else {
      this._router.navigateByUrl('user/submissions');
    }
  }

  public toggleDirectorySearchModal(selectedField?) {
    // If not adminUser then disable toggle
    if (this.adminUser || !this.showCDERDetails === true) {
      this.directorySearchModalVisible = !this.directorySearchModalVisible;

      if (selectedField) {
        this.selectedField = selectedField;
      }
    }

  }

  // Search directory
  public updateFieldsWithUserInfo(selectedUser) {
    switch (this.selectedField) {
      case 'pi':
        this.account.PI = selectedUser.DisplayName;
        this.account.PIEmail = selectedUser.EmailAddress;
        break;
      case 'teacher':
        this.account.Teacher = selectedUser.DisplayName;
        this.account.TeacherEmail = selectedUser.EmailAddress;
        break;
      default:
        break;
    }

    this.directorySearchModalVisible = false;
  }

  public onCheckboxSelect(system, event) {
    if (event === true) {
      this.system = JSON.stringify(this.systemOptions);
    }
    if (system.Name === 'CDER') {
      if (system.checked === true) {
        // Default Rajshekhar Sunderraman (rsunderraman@gsu.edu) as PI
        // Disable field
        this.account.PI = 'Rajshekhar Sunderraman';
        this.account.PIEmail = 'rsunderraman@gsu.edu';
        this.showCDERDetails = true;

      } else if (system.checked === false) {
        this.account.PI = '';
        this.account.PIEmail = '';
        this.showCDERDetails = false;
      }
    } else if (system.Name === 'Recovery Support Portal') {

      if (system.checked === true) {
        // Default Kristal Davidson (kristal.davidson@dbhdd.ga.gov) as PI
        this.account.PI = 'Kristal Davidson';
        this.account.PIEmail = 'kristal.davidson@dbhdd.ga.gov';
      } else if (system.checked === false)  {
        this.account.PI = '';
        this.account.PIEmail = '';
      }
    }

  }

  public checkEmail() {
    // Check if user exists by email
    const email = this.account.Email;

    console.log('email', email)


    // Search AD for email
    this._directorySearchService.searchUsers(email).subscribe((res) => {
      console.log('res', res)


      if (res.length > 1) {

                console.log('res',res)

        this.existingEmail = true;
        this.submitRequest();
      } else {
        // Search AM for email
        this._requestService.getAccountByUserEmail(email).subscribe((res2) => {
          if (res2.length >= 1) {
            this.existingEmail = true;
            this.submitRequest();
          } else {
            this.existingEmail = false;
            this.submitRequest();
          }
        });
      }
    });

  }

  private validateForm(account: Account) {
    this.formErrors = [];

    // If CDER is checked show Country dropdown
    this.systemOptions.forEach(system => {
      if (!account.Country && system.Name === 'CDER' && system.checked === true) {
        this.formErrors.push('country');
        return false;
      }
    });
    if (!account.FirstName) {
      this.formErrors.push('firstName');
      return false;
    }
    if (!account.FirstName.match(/^[A-z0-9]*((-|\s)*[_A-z0-9])*$/)) {
      this.formErrors.push('firstNameRules');
      return false;
    }
    if (!account.LastName) {
      this.formErrors.push('lastName');
      return false;
    }
    if (!account.LastName.match(/^[A-z0-9]*((-|\s)*[_A-z0-9])*$/)) {
      this.formErrors.push('lastNameRules');
      return false;
    }
    if (!account.Email) {
      this.formErrors.push('email');
      return false;
    }
    if (!account.Email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      this.formErrors.push('emailCharacters');
      return false;
    }
    if (account.Email && this.existingEmail === true) {
      this.formErrors.push('emailExists');
      return false;
    }
    if (!account.Company) {
      this.formErrors.push('company');
      return false;
    }
    if (!account.PI) {
      this.formErrors.push('pi');
      return false;
    }
    if (!this.formErrors.length) {
      return true;
    }
  }

  private getCheckedSystem() {
    this.systemOptions.forEach(system => {
      if (system.Name === 'CDER' && system.checked === true) {
        this.checked = 'cder';
      }
      if (system.Name === 'MMPVAE' && system.checked === true) {
        this.checked = 'mmpvae';
      }
    });

  }

  public submitRequest() {
    const valid = this.validateForm(this.account);

    if (valid) {
      this.getCheckedSystem();
      const systemType = this.checked;

      this.account.SubmissionDate = this.today;
      this.account.SubmissionStatus = Constants.submissionStatuses.pending;

      if (this.system === 'string') {
        this.account.System = this.system;
      } else {
        this.account.System = JSON.stringify(this.systemOptions);
      }

      this._requestService.createAccount(this.account).subscribe(
        res => {
          // // Notify PI of request
          this._emailService.createNotificationSubmitted(res.Id, this.account, systemType).subscribe((data) => {

          });
          // Notify Admin of request
          this._emailService.createAdminNotificationSubmitted(res.Id, this.account, systemType).subscribe((data) => {

          });
          this.createNotification('success', 'Submitted for Approval', 'Request successfully submitted');
          // Remove form and show message
          this.requestSubmitted = true;
          this.accountId = res.Id;
          this.requestedByName = res.FirstName + ' ' + res.LastName;
        },
        err => {
          console.log('submit request error: ', err);
          this.createNotification('error', 'Submitted for Approval', 'An error has occurred');
        }
      );

    }

  }

  private updateAccount() {
    this.account.UpdateDate = this.today;
    this.account.System = JSON.stringify(this.systemOptions);

    this._requestService.updateAccount(this.accountId, this.account).subscribe(
      res => {
        this.updateAccountHistory(this.account);
        this.createNotification('success', 'Request Updated', 'Request successfully updated');
        this.showFormInfo(this.accountId);
      },
      err => {
        console.log('update request error: ', err);
        this.createNotification('error', 'Request Updated', 'An error has occurred');
      }
    );
  }

  public approveAccount() {
    this.getCheckedSystem();
    const systemType = this.checked;

    this.account.SubmissionStatus = Constants.submissionStatuses.approved;
    this.account.ApprovedBy = this.user.firstname + ' ' + this.user.lastname;
    this.account.ApprovalDate = this.today;
    this.account.UpdateDate = this.today;

    this._requestService.updateAccount(this.accountId, this.account).subscribe(
      res => {
        // Notify applicant PI approved
        this._emailService.createNotificationApproved(this.accountId, this.account, systemType).subscribe((data) => {

        });
        this.createNotification('success', 'Request Approved', 'Request for Approval status has been updated');
        this.updateAccountHistory(this.account);
        this.showFormInfo(this.accountId);
        this.showApprovedMessage = true;
      },
      err => {
        console.log('update account error: ', err);
        this.createNotification('error', 'Request Approved', 'An error has occurred');
      }
    );

  }

  public toggleDenyModal() {
    this.denyModalVisible = !this.denyModalVisible;
  }

  public denyAccount() {
    this.getCheckedSystem();
    const systemType = this.checked;

    this.account.SubmissionStatus = Constants.submissionStatuses.denied;
    this.account.DenialReason = this.denialReason;
    this.account.DeniedBy = this.user.firstname + ' ' + this.user.lastname;
    this.account.DenialDate = this.today;
    this.account.UpdateDate = this.today;

    this._requestService.updateAccount(this.accountId, this.account).subscribe(
      res => {
        // Notify applicant PI denied
        this._emailService.createNotificationDenied(this.accountId, this.account, systemType).subscribe((data) => {

        });
        this.updateAccountHistory(this.account);
        this.showFormInfo(this.accountId);
        this.toggleDenyModal();
        this.createNotification('success', 'Request Denied', 'Request status has been updated');
      },
      err => {
        console.log('update account error: ', err);
        this.createNotification('error', 'Request Denied', 'An error has occurred');
      }
    );
  }

  private updateAccountHistory(account) {
    account.AccountId = account.Id;

    this._requestService.updateAccountHistory(account).subscribe((data) => {

    },
      err => {
        console.log('save account history error: ', err);
      });
  }

}
