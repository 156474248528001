import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { Constants } from '../../constants/constants';
import { LoaderService } from './loader.service';

@Injectable()
export class SystemTypeService {
  private accountRequestApiUrl = `${Constants.accountRequestApiUrl}/systemtypes`;
  private headers = new Headers({
    'Content-Type': 'application/json',
    'API_KEY': Constants.apiKey
  });

  constructor(
    private _http: Http,
    private _loaderService: LoaderService
  ) { }

  private updateLoader(loading: boolean) {
    setTimeout(() => {
      this._loaderService.updateLoadingStatus(loading);
    }, 0);
  }

  public createSystemType(systemType) {
    this.updateLoader(true);
    return this._http.post(this.accountRequestApiUrl, JSON.stringify(systemType), { headers: this.headers })
      .map(response => {
        this.updateLoader(false);
        return response.json();
      })
      .catch(error => {
        this.updateLoader(false);
        return Observable.throw(error);
      });
  }

  public getSystemTypes() {
    this.updateLoader(true);
    return this._http.get(this.accountRequestApiUrl, { headers: this.headers })
      .map(response => {
        this.updateLoader(false);
        return response.json();
      })
      .catch(error => {
        this.updateLoader(false);
        return Observable.throw(error);
      });
  }

  public getSystemTypeById(systemTypeId) {
    this.updateLoader(true);
    return this._http.get(`${this.accountRequestApiUrl}/${systemTypeId}`, { headers: this.headers })
      .map(response => {
        this.updateLoader(false);
        return response.json();
      })
      .catch(error => {
        this.updateLoader(false);
        return Observable.throw(error);
      });
  }

  public updateSystemType(systemTypeId, systemType) {
    this.updateLoader(true);
    return this._http.put(`${this.accountRequestApiUrl}/${systemTypeId}`, JSON.stringify(systemType), { headers: this.headers })
      .map(response => {
        this.updateLoader(false);
        return response.json();
      })
      .catch(error => {
        this.updateLoader(false);
        return Observable.throw(error);
      });
  }

  public deleteSystemType(systemTypeId) {
    this.updateLoader(true);
    return this._http.delete(`${this.accountRequestApiUrl}/${systemTypeId}`, { headers: this.headers })
      .map(response => {
        this.updateLoader(false);
        return response.json();
      })
      .catch(error => {
        this.updateLoader(false);
        return Observable.throw(error);
      });
  }

}
