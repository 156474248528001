import { OnInit, EventEmitter } from '@angular/core';
var DeleteModalComponent = /** @class */ (function () {
    function DeleteModalComponent() {
        this.closeDeleteModal = new EventEmitter();
    }
    DeleteModalComponent.prototype.ngOnInit = function () {
    };
    DeleteModalComponent.prototype.closeModal = function (deletedAccount) {
        var emittedData = deletedAccount ? deletedAccount : true;
        this.closeDeleteModal.emit(emittedData);
    };
    return DeleteModalComponent;
}());
export { DeleteModalComponent };
