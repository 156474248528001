import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { Subject } from 'rxjs/Rx';
import 'rxjs/add/operator/map';

import { DataTableDirective } from 'angular-datatables';

import { EmailService } from '../../services/email.service';
import { LoaderService } from '../../services/loader.service';
import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../services/notification.service';
import { RequestService } from '../../services/request.service';

import { Constants } from '../../../constants/constants';
import { Email } from '../../../models/email';
import { Account } from '../../../models/account';
import { User } from '../../../models/user';

@Component({
  selector: 'app-submissions',
  templateUrl: './submissions.component.html',
  styleUrls: ['./submissions.component.css']
})
export class SubmissionsComponent implements OnInit, AfterViewInit {
  @Input() public url: string;

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  private accounts: Account;
  private account: any = {};
  private user: User;

  public accountId;
  public deleteModalVisible: boolean;
  private loaderSubscription;
  private loadingInProgress: boolean;
  public mobileDevice: boolean;
  private notificationOptions = Constants.notificationOptions;

  constructor(
    private _loaderService: LoaderService,
    private _loginService: LoginService,
    private _notificationService: NotificationService,
    private _requestService: RequestService,
    private _router: Router,
  ) { }

  ngOnInit() {
    this.mobileDevice = this._loaderService.getMobileStatus();

    this.loaderSubscription = this._loaderService.apiCallUpdate.subscribe(waiting => {
      this.loadingInProgress = waiting;

      if (this.loadingInProgress === false) {
        this.dtTrigger.next();
      }
    });

    this.user = this._loginService.getUser();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      order: [1, 'desc'],
      columnDefs: [{
        targets: 1,
        type: 'num'
      }]
    };
  }

  ngAfterViewInit(): void {
    this._requestService.getAccountsByPIEmail(this.user.email).subscribe((data) => {
      this.dtTrigger.next();
      this.accounts = data;
    });
  }


  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      this._requestService.getAccountsByPIEmail(this.user.email).subscribe((data) => {
        // Call the dtTrigger to rerender
        this.dtTrigger.next();
        this.accounts = data;
      });
    });
  }

  private createNotification(notificationType: string, notificationTitle: string, notificationMessage: string) {
    switch (notificationType) {
      case 'success':
        this._notificationService.createSuccessNotification(notificationTitle, notificationMessage);
        break;
      case 'error':
        this._notificationService.createErrorNotification(notificationTitle, notificationMessage);
        break;
      default:
        break;
    }
  }

  public getSelectedAccount(account) {
    this.account = account;
  }

  public toggleDeleteModal(selectedAccount?) {
    this.deleteModalVisible = !this.deleteModalVisible;

    if (selectedAccount === 'delete') {
      this.deleteAccount(this.account);
    }
  }


  public deleteAccount(account) {
    if (account.Id) {
      this._requestService.deleteAccount(account.Id).subscribe(
        response => {
          this.createNotification('success', 'Account Deleted', 'Account has been deleted');
          this.rerender();
        },
        err => {
          console.log('update conf grant err: ', err);
          this.createNotification('error', 'Account Deleted', 'An error has occurred. Please try again');
        }
      );
    }

  }

}
