import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { DirectorySearchService } from '../../services/directory-search.service';
import { LoaderService } from '../../services/loader.service';
var DirectorySearchModalComponent = /** @class */ (function () {
    function DirectorySearchModalComponent(_directorySearchService, _loaderService) {
        this._directorySearchService = _directorySearchService;
        this._loaderService = _loaderService;
        this.selectUserResult = new EventEmitter();
        this.closeSearchModal = new EventEmitter();
        this.searchMessage = false;
    }
    DirectorySearchModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loaderSubscription = this._loaderService.directoryLoadingUpdate.subscribe(function (loading) {
            _this.isLoading = loading;
        });
    };
    DirectorySearchModalComponent.prototype.ngOnDestroy = function () {
        if (this.loaderSubscription) {
            this.loaderSubscription.unsubscribe();
        }
    };
    DirectorySearchModalComponent.prototype.searchAccounts = function () {
        var _this = this;
        this.searchMessage = false;
        this._directorySearchService.searchUsers(this.searchTerm).subscribe(function (response) {
            var accounts = response;
            if (Array.isArray(accounts) && accounts.length > 0) {
                _this.searchResults = accounts.filter(function (result) {
                    result.mail = result.mail;
                    result.displayName = result.displayName;
                    return result;
                });
            }
            else {
                _this.searchResults = undefined;
                _this.searchMessage = true;
            }
        }, function (error) {
            console.log('search by name error: ', error);
            _this.searchResults = undefined;
            _this.searchMessage = true;
        });
    };
    DirectorySearchModalComponent.prototype.selectUser = function (user) {
        this.selectUserResult.emit(user);
    };
    DirectorySearchModalComponent.prototype.closeModal = function () {
        this.closeSearchModal.emit(true);
    };
    return DirectorySearchModalComponent;
}());
export { DirectorySearchModalComponent };
