import { OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { Subject } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { DataTableDirective } from 'angular-datatables';
import { LoaderService } from '../../services/loader.service';
import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../services/notification.service';
import { RequestService } from '../../services/request.service';
import { Constants } from '../../../constants/constants';
var SubmissionsComponent = /** @class */ (function () {
    function SubmissionsComponent(_loaderService, _loginService, _notificationService, _requestService, _router) {
        this._loaderService = _loaderService;
        this._loginService = _loginService;
        this._notificationService = _notificationService;
        this._requestService = _requestService;
        this._router = _router;
        this.dtOptions = {};
        this.dtTrigger = new Subject();
        this.account = {};
        this.notificationOptions = Constants.notificationOptions;
    }
    SubmissionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mobileDevice = this._loaderService.getMobileStatus();
        this.loaderSubscription = this._loaderService.apiCallUpdate.subscribe(function (waiting) {
            _this.loadingInProgress = waiting;
            if (_this.loadingInProgress === false) {
                _this.dtTrigger.next();
            }
        });
        this.user = this._loginService.getUser();
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            order: [1, 'desc'],
            columnDefs: [{
                    targets: 1,
                    type: 'num'
                }]
        };
    };
    SubmissionsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this._requestService.getAccountsByPIEmail(this.user.email).subscribe(function (data) {
            _this.dtTrigger.next();
            _this.accounts = data;
        });
    };
    SubmissionsComponent.prototype.rerender = function () {
        var _this = this;
        this.dtElement.dtInstance.then(function (dtInstance) {
            // Destroy the table first
            dtInstance.destroy();
            _this._requestService.getAccountsByPIEmail(_this.user.email).subscribe(function (data) {
                // Call the dtTrigger to rerender
                _this.dtTrigger.next();
                _this.accounts = data;
            });
        });
    };
    SubmissionsComponent.prototype.createNotification = function (notificationType, notificationTitle, notificationMessage) {
        switch (notificationType) {
            case 'success':
                this._notificationService.createSuccessNotification(notificationTitle, notificationMessage);
                break;
            case 'error':
                this._notificationService.createErrorNotification(notificationTitle, notificationMessage);
                break;
            default:
                break;
        }
    };
    SubmissionsComponent.prototype.getSelectedAccount = function (account) {
        this.account = account;
    };
    SubmissionsComponent.prototype.toggleDeleteModal = function (selectedAccount) {
        this.deleteModalVisible = !this.deleteModalVisible;
        if (selectedAccount === 'delete') {
            this.deleteAccount(this.account);
        }
    };
    SubmissionsComponent.prototype.deleteAccount = function (account) {
        var _this = this;
        if (account.Id) {
            this._requestService.deleteAccount(account.Id).subscribe(function (response) {
                _this.createNotification('success', 'Account Deleted', 'Account has been deleted');
                _this.rerender();
            }, function (err) {
                console.log('update conf grant err: ', err);
                _this.createNotification('error', 'Account Deleted', 'An error has occurred. Please try again');
            });
        }
    };
    return SubmissionsComponent;
}());
export { SubmissionsComponent };
