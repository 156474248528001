import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import { LoaderService } from './loader.service';
import { Constants } from '../../constants/constants';

@Injectable()
export class DirectorySearchService {
  private graphUrl = Constants.graphUrl;

  constructor(
    private _http: Http,
    private _loaderService: LoaderService
  ) { }

  private updateLoadingStatus(loading: boolean) {
    this._loaderService.updateDirectorySearchLoadingStatus(loading);
  }

  private getAccessToken(): Observable<string> {
    return this._http.get(Constants.authUrl)
      .map((response: Response) => response.json().token)
      .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
  }

  public searchUsers(searchVal: string): Observable<any[]> {
    return this.getAccessToken()
      .switchMap(accessToken => {
        const headers = new Headers({
          'Authorization': `Bearer ${accessToken}`
        });
        const searchUrl = `${this.graphUrl}?$filter=startswith(givenName,'${searchVal}') or startswith(surName,'${searchVal}') or startswith(displayName,'${searchVal}') or startswith(mail,'${searchVal}')`;

        return this._http.get(searchUrl, { headers })
          .map((response: Response) => response.json().value)
          .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
      });
  }

}
