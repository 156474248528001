import { OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DirectorySearchService } from '../../services/directory-search.service';
import { EmailService } from '../../services/email.service';
import { LoaderService } from '../../services/loader.service';
import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../services/notification.service';
import { RequestService } from '../../services/request.service';
import { Constants } from '../../../constants/constants';
var ResetFormComponent = /** @class */ (function () {
    function ResetFormComponent(_activatedRoute, _directorySearchService, _changeDetectorRef, _emailService, _loginService, _loaderService, _notificationService, _requestService, _router) {
        this._activatedRoute = _activatedRoute;
        this._directorySearchService = _directorySearchService;
        this._changeDetectorRef = _changeDetectorRef;
        this._emailService = _emailService;
        this._loginService = _loginService;
        this._loaderService = _loaderService;
        this._notificationService = _notificationService;
        this._requestService = _requestService;
        this._router = _router;
        this.account = {};
        this.existingEmail = false;
        this.formErrors = [];
        this.permissionDenied = false;
        this.requestSubmitted = false;
        this.today = moment().format('MM/DD/YYYY');
    }
    ResetFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Get token from url & pass to getAccountInfo method
        this._activatedRoute.paramMap.subscribe(function (paramMap) {
            _this.token = paramMap.get('token');
            console.log('token', _this.token);
            _this.getAccountInfo(_this.token);
        });
    };
    ResetFormComponent.prototype.toggleLoader = function (loading) {
        this._loaderService.updateLoadingStatus(loading);
    };
    ResetFormComponent.prototype.createNotification = function (notificationType, notificationTitle, notificationMessage) {
        switch (notificationType) {
            case 'success':
                this._notificationService.createSuccessNotification(notificationTitle, notificationMessage);
                break;
            case 'error':
                this._notificationService.createErrorNotification(notificationTitle, notificationMessage);
                break;
            default:
                break;
        }
    };
    ResetFormComponent.prototype.getAccountInfo = function (token) {
        var _this = this;
        this._requestService.getAccountByToken(token).subscribe(function (res) {
            console.log('account info', res);
            // If token exists show reset form
            if (res.length > 0) {
                _this.account = res[0];
            }
            else {
                // Hide reset form and show permissionDenied message
                _this.permissionDenied = true;
            }
        }, function (error) {
            console.log('get account by id error: ', error);
        });
    };
    ResetFormComponent.prototype.validateForm = function (account) {
        this.formErrors = [];
        if (!account.Password) {
            this.formErrors.push('password');
            return false;
        }
        if (!account.Password.match(/^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{10,32}$/)) {
            this.formErrors.push('passwordRules');
            return false;
        }
        if (this.passwordConfirmation !== account.Password) {
            this.formErrors.push('passwordConfirmation');
            return false;
        }
        if (!this.formErrors.length) {
            return true;
        }
    };
    ResetFormComponent.prototype.submitReset = function () {
        var _this = this;
        var valid = this.validateForm(this.account);
        if (valid) {
            // Remove token
            // Update account with reset status
            this.account.Token = null;
            this.account.UpdateDate = this.today;
            this.account.SubmissionStatus = Constants.submissionStatuses.reset;
            this._requestService.updateAccount(this.account.Id, this.account).subscribe(function (res) {
                // Remove form and show submitted message
                _this.requestSubmitted = true;
            }, function (err) {
                console.log('submit request error: ', err);
            });
        }
    };
    return ResetFormComponent;
}());
export { ResetFormComponent };
