import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';
var ChildAuthGuardService = /** @class */ (function () {
    function ChildAuthGuardService(_router, _loginService, _route) {
        this._router = _router;
        this._loginService = _loginService;
        this._route = _route;
    }
    ChildAuthGuardService.prototype.canActivateChild = function (route, state) {
        var isLoggedIn = this._loginService.isLoggedIn(), userRole = this._loginService.getUserRole(), rooute = route.params, id = route.params['id'], generalUser = userRole === 'general' && isLoggedIn, adminUser = userRole === 'admin' && isLoggedIn;
        // General user
        if (generalUser && state.url === '/user/user-account' || generalUser && state.url === '/user/submissions') {
            return true;
        }
        // Admin user
        if (adminUser && state.url === '/user/user-account' || adminUser && state.url === '/user/submissions' || adminUser && state.url === '/user/all-submissions' || adminUser && state.url === '/user/settings') {
            return true;
        }
        else {
            this._router.navigate(['/user/user-account']);
            return false;
        }
    };
    return ChildAuthGuardService;
}());
export { ChildAuthGuardService };
