<div class="help-container">
  <div class="ui middle aligned center aligned grid">
    <div class="column">
      <br>
      <br>
      <div class="main ui container">
        <h1 class="ui header">
          <div class="content">
            How-to Guides
          </div>
        </h1>
        <br>
        <br>
        <div class="ui grid">
          <div class="eight wide column">
            <h2>
                <a href="https://help.rs.gsu.edu/display/PD/How+to+Request+an+Account?src=contextnavpagetreemode" target="blank">Request an account</a>
            </h2>
          </div>
          <div class="eight wide column">
            <h2>
              <a href="https://help.rs.gsu.edu/display/PD/How+to+Approve+an+Account+Request?src=contextnavpagetreemode" target="blank">Approve an account request</a>
            </h2>
          </div>
          <div class="eight wide column">
            <h2>
              <a href="https://help.rs.gsu.edu/display/PD/How+to+Request+Your+Username?src=contextnavpagetreemode" target="blank">Request your username</a>
            </h2>
          </div>
          <div class="eight wide column">
            <h2>
              <a href="https://help.rs.gsu.edu/display/PD/How+to+Reset+Your+Password?src=contextnavpagetreemode" target="blank">Reset your password</a>
            </h2>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>
