import { Injectable } from '@angular/core';

import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class NotificationService {

  constructor(
    private _notificationsService: NotificationsService
  ) { }

  public createSuccessNotification(notificationTitle: string, notificationMessage: string) {
    this._notificationsService.success(notificationTitle, notificationMessage);
  }

  public createErrorNotification(notificationTitle: string, notificationMessage: string) {
    this._notificationsService.error(notificationTitle, notificationMessage);
  }

}
