<div class="login-container">
  <sui-dimmer class="inverted" [isDimmed]="isLoading" [isClickable]="false" [transitionDuration]="200">
    <div class="ui text loader">Loading</div>
  </sui-dimmer>
  <div class="ui middle aligned center aligned grid">
    <div class="column">
      <br>
      <br>
      <br>
      <br>
      <div>
        <img src="https://rsfs.rs.gsu.edu/adfs/portal/logo/logo.jpg?id=16F019CCB44478AEA1118AB149A5BDBFA030A7CDCFD513BD19223E1790741D7B">
      </div>
      <br>
      <div class="main ui container" *ngIf="isLoggedIn">
        <h2 class="ui header">
          <div class="content">
            Logout of your account
          </div>
        </h2>
        <div class="ui fluid large submit button" (click)="logout()">Logout</div>
        <br>
      </div>

      <div class="main ui container" *ngIf="!isLoggedIn">
        <h2 class="ui header">
          <div class="content">
            Login with your GSU account
          </div>
        </h2>
        <form class="ui large form">
          <div>
            <div class="error-message-container">
              <sui-message *ngIf="errorMessage" class="error">
                <div class="header">User Login Error</div>
                <div>{{errorMessage}}</div>
              </sui-message>
            </div>
            <div class="field">
              <div class="ui left icon input">
                <i class="user icon"></i>
                <input [(ngModel)]="username" type="text" placeholder="Username" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <div class="field">
              <div class="ui left icon input">
                <i class="lock icon"></i>
                <input [(ngModel)]="password" (keyup.enter)="login()" type="password" placeholder="Password" [ngModelOptions]="{standalone: true}">
              </div>
            </div>
            <button class="fluid ui large button teal" (click)="login()">Login</button>
            <br>
            <br>
            <div>
              <div class="link">
                <a (click)="togglePasswordResetModal()">Forgot your password?</a>
              </div>
            </div>
            <div class="link">
              <a (click)="toggleUsernameReminderModal()">Forgot your username?</a>
            </div>
            <br>
            <div class="ui fitted divider"></div>
            <br>
            <div class="link">Don't have an account yet? &nbsp;
              <a [routerLink]="['']">Request one here.</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- forgot password modal -->
  <sui-modal *ngIf="passwordResetModalVisible" size="tiny" (dismissed)="togglePasswordResetModal()" #forgotPasswordModal>
    <div class="header">Reset Your Password</div>
    <div class="content">
      <div class="ui form">
        <div *ngIf="!gsuEmail">
          <div><b>Have a GSU account?</b>&nbsp; Go to <a href="https://campusid.gsu.edu/" target="blank">CampusID Password Manager</a>
            to reset your password.</div>
          <br>
          <hr>
          <br>
          <div><b>External to GSU?</b>&nbsp; Enter the email address associated with your account below and we'll send you a link to reset your password.</div>
          <br>
          
        </div>
        
        <div class="field">
          <input name="Name" 
            [(ngModel)]="email" 
            [ngModelOptions]="{ standalone: true }" 
            type="text"
            placeholder="Email">
        </div>
        <!-- <div *ngIf="gsuEmail">
          <br>
          <div>You have a GSU account. Please go to <a href="https://campusid.gsu.edu/">CampusID Password Manager</a>
            to reset your password.</div>
        </div> -->
        <div *ngIf="formErrors.indexOf('emailGSU') >= 0" class="form-error">You have a GSU account.&nbsp; Go to <a href="https://campusid.gsu.edu/" target="blank">CampusID Password Manager</a>
        to reset your password.</div>
        
        <div *ngIf="formErrors.indexOf('email') >= 0" class="form-error">This field is required</div>
        <div *ngIf="formErrors.indexOf('emailDoesntExist') >= 0" class="form-error">There is not an account associated with this email. Please create an account.</div>
        <div *ngIf="formErrors.indexOf('emailCharacters') >= 0" class="form-error">Email address must contain '@' and '.' symbols.</div>
      </div>
    </div>
    <div class="actions">
      <button class="ui small button" (click)="togglePasswordResetModal()">Cancel</button>
      <button class="ui small primary button" (click)="checkEmail('password')">Send password reset email</button>
    </div>
  </sui-modal>

  <!-- forgot username modal -->
  <sui-modal *ngIf="usernameReminderModalVisible" size="tiny" (dismissed)="toggleUsernameReminderModal()" #forgotUsernameModal>
    <div class="header">Username Reminder</div>
    <div class="content">
      <div class="ui form">
        <div>Enter the email address associated with your account and we'll send you your Username.</div>
        <br>
        <div class="field">
          <input name="Name" 
            [(ngModel)]="email" 
            [ngModelOptions]="{ standalone: true }" 
            type="text" 
            placeholder="Email">
        </div>
        <div *ngIf="formErrors.indexOf('email') >= 0" class="form-error">This field is required</div>
        <div *ngIf="formErrors.indexOf('emailDoesntExist') >= 0" class="form-error">There is not an account associated with this email. Please create an account.</div>
        <div *ngIf="formErrors.indexOf('emailCharacters') >= 0" class="form-error">Email address must contain '@' and '.' symbols.</div>
      </div>
    </div>
    <div class="actions">
      <button class="ui small button" (click)="toggleUsernameReminderModal()">Cancel</button>
      <button class="ui small primary button" (click)="checkEmail('username')">Send username reminder email</button>
    </div>
  </sui-modal>

</div>
