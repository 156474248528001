<div class="form-container">
  <sui-dimmer class="inverted" [isDimmed]="isLoading" [isClickable]="false" [transitionDuration]="200">
    <div class="ui text loader">Loading</div>
  </sui-dimmer>
  <div class="ui middle aligned centered grid">
    <div class="column">
      <div class="ui container">
        <h1 class="ui left aligned header">My Account</h1>
        <!-- account not activated -->
        <div *ngIf="!accountActivated">
          <div class="ui form">
            <br>
            <div *ngIf="showDenialReason" class="ui red tertiary inverted segment">
              <div class="field">
                <h4 class="ui header">Reason for Denial:</h4>
                <div>{{ denialReason }}</div>
              </div>
            </div>
            <div class="field">
              <label>First Name</label>
              <input class="ui disabled field" name="FirstName" [(ngModel)]="account.FirstName" [ngModelOptions]="{standalone: true}" type="text"
                placeholder="First Name">
            </div>
            <div class="field">
              <label>Last Name</label>
              <input class="ui disabled field" [(ngModel)]="account.LastName" [ngModelOptions]="{standalone: true}" type="text" placeholder="Last Name">
            </div>
            <div class="field">
              <label>Email</label>
              <input class="ui disabled field" name="Email" [(ngModel)]="account.Email" [ngModelOptions]="{standalone: true}" type="text"
                placeholder="Email">
            </div>
            <!-- update temporary password -->
            <div *ngIf="updatePassword">
              <br>
              <h2 class="ui left aligned header">Update Temporary Password</h2>
              <div class="ui divider header-divider"></div>
              <!-- <div class="field">
                <label>Username</label>
                <input class="ui disabled field" name="Username" [(ngModel)]="account.Username" [ngModelOptions]="{standalone: true}" type="text"
                  placeholder="Username">
              </div> -->
              <br>
              <label class="form-error">Your new password must meet the following requirements:</label>
              <div class="ui mini bulleted list form-error">
                <div class="item">10 - 32 characters in length</div>
                <div class="item">must start with a letter</div>
                <div class="item">previously used passwords are not allowed</div>
                <div class="item">at least one upper case letter</div>
                <div class="item">at least one lower case letter</div>
                <div class="item">at least one number</div>
                <div class="item">must not contain these special characters: @ / () " ' `</div>
                <div class="item">passwords expire every 120 days</div>
              </div>
              <br>
              <div class="required field">
                <label>New Password</label>
                <input name="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" type="password" placeholder="Enter New Password">
                <div *ngIf="formErrors.indexOf('password') >= 0" class="form-error">This field is required</div>
                <div *ngIf="formErrors.indexOf('passwordRules') >= 0" class="form-error">Your password must follow the above requirements</div>
              </div>
              <div class="required field">
                <label>Confirm New Password</label>
                <input name="passwordConfirmation" [(ngModel)]="passwordConfirmation" [ngModelOptions]="{standalone: true}" type="password"
                  placeholder="Confirm New Password">
                <div *ngIf="formErrors.indexOf('passwordConfirmation') >= 0" class="form-error">Passwords must match</div>
              </div>
              <br>
              <div class="ui divider"></div>
              <br>
              <button (click)="updateAccount()" class="ui button primary">Update Password</button>
              <br>
            </div>
          </div>
        </div>
        
        <!-- account activated -->
        <div *ngIf="accountActivated">
          <div class="ui form">
            <div class="field">
              <label>First Name</label>
              <input class="ui disabled field" name="FirstName" [(ngModel)]="account.FirstName" [ngModelOptions]="{standalone: true}" type="text"
                placeholder="First Name">
            </div>
            <div class="field">
              <label>Last Name</label>
              <input class="ui disabled field" name="LastName" [(ngModel)]="account.LastName" [ngModelOptions]="{standalone: true}" type="text"
                placeholder="Last Name">
            </div>
            <div class="field">
              <label>Email</label>
              <input class="ui disabled field" name="Email" [(ngModel)]="account.Email" [ngModelOptions]="{standalone: true}" type="text"
                placeholder="Email">
            </div>
          </div>
          <br>
          <br>
          <!-- <button (click)="toggleChangePasswordModal()" class="ui button" *ngIf="clubhouseUser">Change your password</button>
           -->
        </div>    
      </div>
      <br>
      <br>
      <br>
    </div>
  </div>
</div>

<!-- change password modal -->
<sui-modal *ngIf="changePasswordModalVisible" size="tiny" (dismissed)="toggleChangePasswordModal()" #forgotPasswordModal>
  <div class="header">Change Your Password</div>
  <div class="content">
    <div class="ui form">
      <label class="form-error">Your new password must meet the following requirements:</label>
      <div class="ui mini bulleted list form-error">
        <div class="item">10 - 32 characters in length</div>
        <div class="item">must start with a letter</div>
        <div class="item">previously used passwords are not allowed</div>
        <div class="item">at least one upper case letter</div>
        <div class="item">at least one lower case letter</div>
        <div class="item">at least one number</div>
        <div class="item">must not contain these special characters: @ / () " ' `</div>
        <div class="item">passwords expire every 120 days</div>
      </div>
      <br>
      <div class="required field">
        <label>New Password</label>
        <input name="password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" type="password" placeholder="Enter New Password">
        <div *ngIf="formErrors.indexOf('password') >= 0" class="form-error">This field is required</div>
        <div *ngIf="formErrors.indexOf('passwordRules') >= 0" class="form-error">Your password must follow the above requirements</div>
      </div>
      <div class="required field">
        <label>Confirm New Password</label>
        <input name="passwordConfirmation" [(ngModel)]="passwordConfirmation" [ngModelOptions]="{standalone: true}" type="password"
          placeholder="Confirm New Password">
        <div *ngIf="formErrors.indexOf('passwordConfirmation') >= 0" class="form-error">Passwords must match</div>
      </div>
    </div>
  </div>
  <div class="actions">
    <button (click)="changePassword()" class="ui button primary">Update Password</button>
  </div>
</sui-modal>
