import { OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Constants } from '../../../constants/constants';
import { DirectorySearchService } from '../../services/directory-search.service';
import { EmailService } from '../../services/email.service';
import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../services/notification.service';
import { RequestService } from '../../services/request.service';
import { TokenService } from '../../services/token.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(_directorySearchService, _emailService, _loginService, _notificationService, _requestService, _router, _route, _tokenService) {
        this._directorySearchService = _directorySearchService;
        this._emailService = _emailService;
        this._loginService = _loginService;
        this._notificationService = _notificationService;
        this._requestService = _requestService;
        this._router = _router;
        this._route = _route;
        this._tokenService = _tokenService;
        this.account = {};
        this.existingEmail = false;
        this.existingEmails = [];
        this.formErrors = [];
        this.gsuEmail = false;
        this.isLoggedIn = this._loginService.isLoggedIn();
        this.today = moment().format('MM/DD/YYYY');
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.routeSubscription = this._route.queryParams.subscribe(function (params) {
            _this.redirectRoute = params['return'] || '';
        });
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        this.routeSubscription.unsubscribe();
    };
    LoginComponent.prototype.createNotification = function (notificationType, notificationTitle, notificationMessage) {
        switch (notificationType) {
            case 'success':
                this._notificationService.createSuccessNotification(notificationTitle, notificationMessage);
                break;
            case 'error':
                this._notificationService.createErrorNotification(notificationTitle, notificationMessage);
                break;
            default:
                break;
        }
    };
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.errorMessage = undefined;
        this._loginService.login(this.username, this.password).subscribe(function (response) {
            if (response.error) {
                _this.errorMessage = response.error;
            }
            else {
                // do the routing logic updates here
                if (_this.redirectRoute === '') {
                    _this._router.navigateByUrl('user/user-account');
                }
                else {
                    _this._router.navigateByUrl(_this.redirectRoute);
                }
            }
        }, function (error) {
            console.log('login error: ', error);
        });
    };
    LoginComponent.prototype.togglePasswordResetModal = function () {
        this.passwordResetModalVisible = !this.passwordResetModalVisible;
    };
    LoginComponent.prototype.toggleUsernameReminderModal = function () {
        this.usernameReminderModalVisible = !this.usernameReminderModalVisible;
    };
    LoginComponent.prototype.checkEmail = function (type) {
        // Check if user exists in AD by email
        if (type === 'password') {
            this.submitPasswordReset();
        }
        if (type === 'username') {
            this.submitUsernameReminder();
        }
    };
    LoginComponent.prototype.validateForm = function (email) {
        this.formErrors = [];
        // If no email
        if (!this.email) {
            this.formErrors.push('email');
            return false;
        }
        // if (this.email && this.existingEmail === false) {
        //   this.formErrors.push('emailDoesntExist');
        //   return false;
        // }
        if (this.email.includes('gsu.edu', 'student.gsu.edu')) {
            console.log('gsu email');
            this.gsuEmail = true;
            this.formErrors.push('emailGSU');
            return false;
        }
        if (!this.formErrors.length) {
            return true;
        }
    };
    LoginComponent.prototype.submitPasswordReset = function () {
        var _this = this;
        var valid = this.validateForm(this.email);
        if (valid) {
            // Check if reset already requested
            this._requestService.getAccountByUserEmail(this.email).subscribe(function (res) {
                if (res.length >= 1 && res[res.length - 1].SubmissionStatus === 'Reset Requested') {
                    // Resend email with token
                    _this.isLoading = true;
                    _this.togglePasswordResetModal();
                    _this._emailService.createNotificationForgotPassword(_this.email, res[res.length - 1].Username, res[res.length - 1].Token).subscribe(function (res3) {
                        _this.isLoading = false;
                        _this.createNotification('success', 'Password Reset Requested', 'We will send you an email with a link to reset your password.');
                    });
                }
                else if (res.length >= 1 && res[res.length - 1].SubmissionStatus !== 'Reset Requested') {
                    _this.isLoading = true;
                    _this.togglePasswordResetModal();
                    // Create new request if no previous reset request
                    // Get random token
                    _this._tokenService.getToken(16).subscribe(function (res2) {
                        var token = res2.access_token;
                        // Create account
                        _this.account.Username = res[res.length - 1].Username;
                        _this.account.Email = _this.email;
                        _this.account.Token = token;
                        _this.account.UpdateDate = _this.today;
                        _this.account.SubmissionStatus = Constants.submissionStatuses.resetRequested;
                        _this._requestService.createAccount(_this.account).subscribe(function (res3) {
                            _this.updateAccountHistory(_this.account);
                            // Pass token to email object where it is appended to reset form route
                            _this._emailService.createNotificationForgotPassword(_this.email, res[res.length - 1].Username, token).subscribe(function (res4) {
                                _this.isLoading = false;
                                _this.createNotification('success', 'Password Reset Requested', 'We will send you an email with a link to reset your password.');
                            });
                        }, function (err) {
                            console.log('update request error: ', err);
                            _this.createNotification('error', 'Password Reset Email', 'An error has occurred');
                        });
                    }, function (err) {
                        console.log('update request error: ', err);
                        _this.createNotification('error', 'Password Reset Email', 'An error has occurred');
                    });
                }
                else {
                    // no account found
                    _this.createNotification('success', 'No Account Found', 'There is not an account associated with this email. Please create an account.');
                }
            });
        }
    };
    LoginComponent.prototype.updateAccountHistory = function (account) {
        account.AccountId = account.Id;
        this._requestService.updateAccountHistory(account).subscribe(function (data) {
        }, function (err) {
            console.log('save account history error: ', err);
        });
    };
    LoginComponent.prototype.submitUsernameReminder = function () {
        var _this = this;
        var valid = this.validateForm(this.email);
        if (valid) {
            var splitEmail = this.email.split('@'), username = splitEmail[0];
            // Notify PI of request
            this._emailService.createNotificationUsernameReminder(this.email, username).subscribe(function (data) {
                _this.toggleUsernameReminderModal();
                _this.createNotification('success', 'Username Reminder Email Sent', 'Request email successfully sent');
            });
        }
    };
    LoginComponent.prototype.logout = function () {
        this.isLoggedIn = false;
        this._loginService.logout();
    };
    return LoginComponent;
}());
export { LoginComponent };
