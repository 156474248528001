import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class LoaderService {
  public loading = new Subject<boolean>();
  public directorySearchLoading = new Subject<boolean>();
  public apiCall = new Subject<boolean>();
  public loadingUpdate = this.loading.asObservable();
  public directoryLoadingUpdate = this.directorySearchLoading.asObservable();
  public apiCallUpdate = this.apiCall.asObservable();
  private mobileDevice: boolean;

  private waitingOnApi: boolean;

  constructor() { }

  public updateLoadingStatus(loading: boolean) {
    this.loading.next(loading);
  }

  public updateDirectorySearchLoadingStatus(loading: boolean) {
    this.directorySearchLoading.next(loading);
  }

  public updateApiWaitingStatus(waiting: boolean) {
    this.apiCall.next(waiting);
  }

  public getMobileStatus() {
    if (this.mobileDevice === undefined) {
      this.mobileDevice = (window.innerWidth <= 800) ? true : false;
      return this.mobileDevice;
    } else {
      return this.mobileDevice;
    }
  }
}
