<div class="404-container">
  <div class="ui middle aligned center aligned grid">
    <div class="column">
      <br>
      <br>
      <br>
      <br>
      <div>
        <img
          src="https://rsfs.rs.gsu.edu/adfs/portal/logo/logo.jpg?id=16F019CCB44478AEA1118AB149A5BDBFA030A7CDCFD513BD19223E1790741D7B">
      </div>
      <br>
      <div class="main ui container">
        <h2 class="ui header">
          <div class="content">
            Oops! The page you're looking for couldn't be found.
          </div>
        </h2>
        <p>Go back <a [routerLink]="['']">home</a>.</p>
        <p>Still need help? Please contact
          <a href="mailto:researchsolutions@gsu.edu">Research Solutions</a>.
        </p>
        <br>
      </div>
    </div>
  </div>
</div>
