import { OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { Subject } from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import { DataTableDirective } from 'angular-datatables';
import { LoaderService } from '../../services/loader.service';
import { LoginService } from '../../services/login.service';
import { NotificationService } from '../../services/notification.service';
import { SystemTypeService } from '../../services/system-type.service';
import { Constants } from '../../../constants/constants';
var SettingsComponent = /** @class */ (function () {
    function SettingsComponent(_activatedRoute, _loaderService, _loginService, _notificationService, _router, _systemTypeService) {
        this._activatedRoute = _activatedRoute;
        this._loaderService = _loaderService;
        this._loginService = _loginService;
        this._notificationService = _notificationService;
        this._router = _router;
        this._systemTypeService = _systemTypeService;
        this.dtOptions = {};
        this.dtTrigger = new Subject();
        this.systems = {};
        this.systemType = {};
        this.system = {};
        this.adminUser = false;
        this.formErrors = [];
        this.notificationOptions = Constants.notificationOptions;
        this.singleSystem = false;
    }
    SettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mobileDevice = this._loaderService.getMobileStatus();
        this.loaderSubscription = this._loaderService.apiCallUpdate.subscribe(function (waiting) {
            _this.loadingInProgress = waiting;
            if (_this.loadingInProgress === false) {
                _this.dtTrigger.next();
            }
        });
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            order: [1, 'desc'],
        };
        // Admin
        // this.adminSubscription = this._loginService.adminUserUpdate.subscribe(userAdmin => {
        //   this.adminUser = userAdmin;
        // });
        // this._loginService.updateAdminStatus(this._loginService.getUser());
        this.user = this._loginService.getUser();
        this.userEmail = this._loginService.getUserEmail();
        this.systems = Constants.systemTypes;
    };
    SettingsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this._systemTypeService.getSystemTypes().subscribe(function (data) {
            _this.dtTrigger.next();
            _this.systems = data;
        });
    };
    SettingsComponent.prototype.toggleLoader = function (loading) {
        this._loaderService.updateLoadingStatus(loading);
    };
    SettingsComponent.prototype.rerender = function () {
        var _this = this;
        this.dtElement.dtInstance.then(function (dtInstance) {
            // Destroy the table first
            dtInstance.destroy();
            _this._systemTypeService.getSystemTypes().subscribe(function (data) {
                // Call the dtTrigger to rerender
                _this.dtTrigger.next();
                _this.systems = data;
            });
        });
    };
    SettingsComponent.prototype.createNotification = function (notificationType, notificationTitle, notificationMessage) {
        switch (notificationType) {
            case 'success':
                this._notificationService.createSuccessNotification(notificationTitle, notificationMessage);
                break;
            case 'error':
                this._notificationService.createErrorNotification(notificationTitle, notificationMessage);
                break;
            default:
                break;
        }
    };
    SettingsComponent.prototype.toggleSystemTypeModal = function () {
        this.systemTypeModalVisible = !this.systemTypeModalVisible;
    };
    SettingsComponent.prototype.addSystem = function () {
        this.singleSystem = false;
    };
    SettingsComponent.prototype.submitSystemType = function () {
        var _this = this;
        this._systemTypeService.createSystemType(this.system).subscribe(function (response) {
            // tslint:disable-next-line:max-line-length
            _this.createNotification('success', 'Submit Sytem', 'System Type successfully saved');
            _this.rerender();
            _this.toggleSystemTypeModal();
        }, function (err) {
            console.log('submit submission error: ', err);
            _this.createNotification('error', 'Submit Sytem', 'An error has occurred');
        });
    };
    SettingsComponent.prototype.getSelectedSystemType = function (system) {
        this.system = system;
    };
    SettingsComponent.prototype.showSystemTypeInfo = function (system) {
        var _this = this;
        this._systemTypeService.getSystemTypeById(system.Id).subscribe(function (res) {
            _this.system = res;
            _this.singleSystem = true;
        }, function (error) {
            console.log('get submission by id error: ', error);
        });
    };
    SettingsComponent.prototype.updateSystemType = function () {
        var _this = this;
        this._systemTypeService.updateSystemType(this.system.Id, this.system).subscribe(function (response) {
            _this.createNotification('success', 'Update Submission', 'Submission successfully saved');
            _this.rerender();
            _this.toggleSystemTypeModal();
        }, function (err) {
            console.log('update conf grant submission error: ', err);
            _this.createNotification('error', 'Update Submission', 'An error has occurred');
        });
    };
    SettingsComponent.prototype.toggleDeleteModal = function (selectedSystem) {
        this.deleteModalVisible = !this.deleteModalVisible;
        if (selectedSystem === 'delete') {
            this.deleteSystem(this.system);
        }
    };
    SettingsComponent.prototype.deleteSystem = function (system) {
        var _this = this;
        if (system.Id) {
            this._systemTypeService.deleteSystemType(system.Id).subscribe(function (response) {
                _this.createNotification('success', 'Delete Submission', 'Submission has been deleted');
                _this.rerender();
            }, function (err) {
                console.log('update conf grant err: ', err);
                _this.createNotification('error', 'Delete Submission', 'An error has occurred. Please try again');
            });
        }
    };
    return SettingsComponent;
}());
export { SettingsComponent };
