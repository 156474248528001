import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { Constants } from '../../constants/constants';

import { Email } from '../../models/email';

@Injectable()
export class EmailService {
  private emailApiUrl = `${Constants.emailApiUrl}`;
  private headers = new Headers({
    'Content-Type': 'application/json'
  });

  private email: Email = new Email();

  constructor(
    private _http: Http
  ) { }

  public createNotificationSubmitted(id, submission, systemType) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200'
    });

    if (systemType === 'mmpvae') {
      // Notify MMPVAE Admin
      this.email.fromEmail = 'Account Management - MMPVAE Admin';
      this.email.approver = submission.PI;
      this.email.toEmail = submission.PIEmail + ',amurray36@gsu.edu';
      this.email.messageType = 'pending';
      this.email.subject = 'Request Pending Approval';
      this.email.submissionId = id;
      this.email.isImportant = true;
      this.email.applicant = submission.FirstName + ' ' + submission.LastName;

      return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers })
        .map(res => res.json())
        .catch((err: any) => Observable.throw(err.json().err || 'Server error'));

    } else {
      this.email.fromEmail = 'Account Management';
      this.email.approver = submission.PI;
      this.email.toEmail = submission.PIEmail;
      this.email.messageType = 'pending';
      this.email.subject = 'Request Pending Approval';
      this.email.submissionId = id;
      this.email.isImportant = true;
      this.email.applicant = submission.FirstName + ' ' + submission.LastName;

      return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers })
        .map(res => res.json())
        .catch((err: any) => Observable.throw(err.json().err || 'Server error'));
    }

  }

  public createAdminNotificationSubmitted(id, submission, systemType) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200'
    });

    if (systemType === 'cder') {
      // Notify CDER Admin
      this.email.fromEmail = 'Account Management - CDER';
      this.email.approver = submission.PI;
      this.email.toEmail = 'hpc@rs.gsu.edu';
      this.email.messageType = 'pendingAdmin';
      this.email.subject = 'Account Requested - CDER';
      this.email.submissionId = id;
      this.email.isImportant = true;
      this.email.applicant = submission.FirstName + ' ' + submission.LastName;

      return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers })
        .map(res => res.json())
        .catch((err: any) => Observable.throw(err.json().err || 'Server error'));

    } else {
      this.email.fromEmail = 'Account Management';
      this.email.approver = submission.PI;
      this.email.toEmail = 'sbesozzi@gsu.edu,ylukinov@gsu.edu';
      this.email.messageType = 'pendingAdmin';
      this.email.subject = 'Account Requested - Admin';
      this.email.submissionId = id;
      this.email.isImportant = true;
      this.email.applicant = submission.FirstName + ' ' + submission.LastName;

      return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers })
        .map(res => res.json())
        .catch((err: any) => Observable.throw(err.json().err || 'Server error'));
    }

  }

  public createNotificationApproved(id, submission, systemType) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200'
    });

    if (systemType === 'mmpvae') {
      // Notify MMPVAE Admin
      this.email.fromEmail = 'Account Management - MMPVAE Admin';
      this.email.toEmail = submission.Email + ',amurray36@gsu.edu';
      this.email.messageType = 'approved';
      this.email.subject = 'Request Approved';
      this.email.submissionId = id;
      this.email.isImportant = true;
      this.email.applicant = submission.FirstName + ' ' + submission.LastName;
      this.email.approver = submission.ApprovedBy;

      console.log('mmpvae', this.email);

      return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers })
        .map(res => res.json())
        .catch((err: any) => Observable.throw(err.json().err || 'Server error'));
    } else {
      this.email.fromEmail = 'Account Management';
      this.email.toEmail = submission.Email;
      this.email.messageType = 'approved';
      this.email.subject = 'Request Approved';
      this.email.submissionId = id;
      this.email.isImportant = true;
      this.email.applicant = submission.FirstName + ' ' + submission.LastName;
      this.email.approver = submission.ApprovedBy;

      return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers })
        .map(res => res.json())
        .catch((err: any) => Observable.throw(err.json().err || 'Server error'));
    }

  }

  public createNotificationDenied(id, submission, systemType) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200'
    });

    if (systemType === 'mmpvae') {
      // Notify MMPVAE Admin
      this.email.fromEmail = 'Account Management - MMPVAE Admin';
      this.email.toEmail = submission.Email + ',amurray36@gsu.edu';
      this.email.messageType = 'denied';
      this.email.subject = 'Request Denied';
      this.email.submissionId = id;
      this.email.isImportant = true;
      this.email.denialReason = submission.DenialReason;
      this.email.applicant = submission.FirstName + ' ' + submission.LastName;
      this.email.approver = submission.ApprovedBy;

      return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers })
        .map(res => res.json())
        .catch((err: any) => Observable.throw(err.json().err || 'Server error'));
    } else {
      this.email.fromEmail = 'Account Management';
      this.email.toEmail = submission.Email;
      this.email.messageType = 'denied';
      this.email.subject = 'Request Denied';
      this.email.submissionId = id;
      this.email.isImportant = true;
      this.email.denialReason = submission.DenialReason;
      this.email.applicant = submission.FirstName + ' ' + submission.LastName;
      this.email.approver = submission.ApprovedBy;

      return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers })
        .map(res => res.json())
        .catch((err: any) => Observable.throw(err.json().err || 'Server error'));
    }

  }

  public createAdminNotificationAccountActivated(id, submission, systemType) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200'
    });

    if (systemType === 'mmpvae') {
      // Notify MMPVAE Admin
      this.email.fromEmail = 'Account Management - MMPVAE Admin';
      this.email.toEmail = 'amurray36@gsu.edu';
      this.email.messageType = 'activated';
      this.email.subject = 'Account Activated';
      this.email.submissionId = id;
      this.email.isImportant = true;
      this.email.denialReason = submission.DenialReason;
      this.email.applicant = submission.FirstName + ' ' + submission.LastName;
      this.email.approver = submission.ApprovedBy;

      return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers })
        .map(res => res.json())
        .catch((err: any) => Observable.throw(err.json().err || 'Server error'));
    } else {

    }

  }

  public createNotificationUsernameReminder(email, username) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200'
    });

    this.email.fromEmail = 'Account Management';
    this.email.toEmail = email;
    this.email.messageType = 'username';
    this.email.subject = 'Username Reminder';
    this.email.isImportant = true;
    this.email.username = username;

    return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers })
      .map(res => res.json())
      .catch((err: any) => Observable.throw(err.json().err || 'Server error'));
  }


  public createNotificationForgotPassword(email, username, token) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200'
    });

    this.email.fromEmail = 'Account Management';
    this.email.toEmail = email;
    this.email.messageType = 'forgot';
    this.email.subject = 'Forgot Password';
    this.email.isImportant = true;
    this.email.username = username;
    this.email.token = token;

    return this._http.post(this.emailApiUrl, JSON.stringify(this.email), { headers })
      .map(res => res.json())
      .catch((err: any) => Observable.throw(err.json().err || 'Server error'));
  }

}
