import { Http, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Constants } from '../../constants/constants';
var TokenService = /** @class */ (function () {
    function TokenService(_http) {
        this._http = _http;
        this.tokenApiUrl = "" + Constants.tokenApiUrl;
        this.headers = new Headers({
            'Content-Type': 'application/json',
            'API_KEY': Constants.apiKey
        });
    }
    TokenService.prototype.getToken = function (size) {
        return this._http.get("" + this.tokenApiUrl, { headers: this.headers })
            .map(function (response) {
            return response.json();
        })
            .catch(function (error) {
            return Observable.throw(error);
        });
    };
    return TokenService;
}());
export { TokenService };
